import Storage from "Core/utils/storage";
import axios, { AxiosError, AxiosResponse } from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const interceptors = {
  onSuccess: (response: AxiosResponse) => {
    return response;
  },
  onError: (e: AxiosError) => {
    const message = e.response?.data.message || e.message || "Unknown error occured.";
    throw message;
  },
};

api.interceptors.response.use(interceptors.onSuccess, interceptors.onError);

const token = Storage.getToken();

if (token) {
  api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default api;
