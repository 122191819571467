import HomePage from "./pages/HomePage";
import EulaPage from "./pages/EulaPage";
import TermsPage from "./pages/TermsPage";
import PrivacyPage from "./pages/PrivacyPage";
import { Route, Routes } from "react-router-dom";
import TrustedFormProgramPage from "./pages/TrustedFormProgramPage";

const Public: React.FC = () => {
  return (
    <Routes>
      <Route index element={<HomePage />} />
      <Route path="tos" element={<TermsPage />} />
      <Route path="privacy" element={<PrivacyPage />} />
      <Route path="eula" element={<EulaPage />} />
      <Route path="mobile/program/:id/trustedform" element={<TrustedFormProgramPage />} />
    </Routes>
  );
};

export default Public;
