import { useState } from "react";
import { Modal } from "Core/components";
import styles from "./styles.module.scss";
import ProfileCard from "Profile/components/ProfileCard";
import useTranslate from "Core/context/translate";

const TermsDrawer: React.FC = () => {
  const { t } = useTranslate();
  const [visible, setVisible] = useState(false);

  const onToggle = (e: boolean) => () => {
    setVisible(e);
  };

  return (
    <>
      <Modal visible={visible} onClose={onToggle(false)} drawer>
        <div className={styles.container}>
          <h2>{t("terms.title")}</h2>
          <h3>{t("terms.subtitle")}</h3>
          <p>{t("terms.description")}</p>
        </div>
      </Modal>
      <div onClick={onToggle(true)}>
        <ProfileCard title={t("profile.menu.terms.title")} info={t("profile.menu.terms.description")} />
      </div>
    </>
  );
};

export default TermsDrawer;
