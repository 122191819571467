import styles from "./styles.module.scss";
import ResourceUrl from "Public/assets/resource.svg";

const Resource: React.FC = () => {
  return (
    <section id="Resources" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <h4>It’s More Than Resources, It’s A Tool To Help Overcome Life’s Challenges</h4>
          <p>
            Seeking the right resources can be a challenge due to the multitude of websites, requirements, and scams.
            ReliefScout is a reputable application that helps you find support from local and nationwide organizations
            in minutes.
          </p>
        </div>
        <div className={styles.right}>
          <div className={styles.overlay} />
          <img src={ResourceUrl} alt="Resources" />
        </div>
      </div>
    </section>
  );
};

export default Resource;
