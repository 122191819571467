import { useState } from "react";
import programApi from "Program/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import surveyUtils from "Core/utils/survey";
import { useNavigate } from "react-router-dom";
import { Program } from "Program/types/Program";
import useTranslate from "Core/context/translate";
import { SurveyAnswer, SurveyAnswerList } from "Core/types/Survey";
import ProgramSurveySteps from "Program/components/ProgramSurveySteps";
import { Button, LoadingOverlay, OpenModal, Question } from "Core/components";

type Props = {
  value: Program;
  onResponse?: (e: string) => void;
};

const ProgramDetailSurvey: React.FC<Props> = ({ value, onResponse }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const [active, setActive] = useState(0);
  const applyReq = useRequest(programApi.apply, { showError: true });
  const completeReq = useRequest(programApi.complete, { showError: true });
  const [answers, setAnswers] = useState<SurveyAnswerList>(surveyUtils.createAnswers(value.missingQuestions));

  const question = value.missingQuestions[active];
  const answer = surveyUtils.getAnswer(question, answers);

  const handleOnChange = (e: SurveyAnswer) => {
    const index = answers.findIndex((x) => x.questionID === e.questionID);
    if (index > -1) {
      answers[index] = e;
      setAnswers([...answers]);
    } else {
      setAnswers((prev) => [...prev, e]);
    }
  };

  const handleOnFinish = () => {
    completeReq.request(value._id, answers).then(() => {
      applyReq.request(value._id).then(() => {
        OpenModal({
          title: t("program.subprofile.apply.success.title"),
          cancel: t("program.subprofile.apply.success.cancel"),
          ok: t("program.subprofile.apply.success.newResources"),
          info: t("program.subprofile.apply.success.description"),
        })
          .then(() => {
            onResponse ? onResponse("resource") : navigate("/resource");
          })
          .catch(() => {
            onResponse ? onResponse("back") : navigate(-1);
          });
      });
    });
  };

  const handleOnPrev = () => {
    setActive(active - 1);
  };

  const handleOnNext = () => {
    active === value.missingQuestions.length - 1 ? handleOnFinish() : setActive(active + 1);
  };

  return (
    <div className={styles.container}>
      <LoadingOverlay loading={completeReq.loading || applyReq.loading}>
        <ProgramSurveySteps active={active} value={value} />
        <div className={styles.content}>
          <Question onChange={handleOnChange} question={question} answer={answer} />
          <div className={styles.buttons}>
            <Button onClick={handleOnPrev} type="outline" disabled={active === 0}>
              Prev
            </Button>
            <Button onClick={handleOnNext} type="primary">
              {active === value.missingQuestions.length - 1 ? "Finish" : "Next"}
            </Button>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default ProgramDetailSurvey;
