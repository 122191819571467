import cx from "Core/utils/cx";
import { useState } from "react";
// import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import LogoUrl from "Core/assets/logo.svg";
import useScroll from "Core/hooks/useScroll";
import CoreHeader from "Core/components/Header";
// import AuthButton from "Auth/components/AuthButton";
import MobileMenu from "Public/components/MobileMenu";
// import AccountButton from "Auth/components/AccountButton";
import NavigationMenu from "Public/components/NavigationMenu";

const Header: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useScroll((top) => {
    setScrolled(top > 60);
    setVisible(top > 400);
  });

  return (
    <div className={cx(styles.container, [styles.scrolled, scrolled], [styles.visible, visible])}>
      <CoreHeader
        left={
          <div className={styles.left}>
            <img src={LogoUrl} alt="ReliefScout" />
            <MobileMenu />
          </div>
        }
        center={
          <div className={styles.center}>
            <NavigationMenu />
            <img src={LogoUrl} alt="ReliefScout" />
          </div>
        }
        // right={
        //   <div className={styles.right}>
        //     <AuthButton>
        //       <Link to="/program">
        //         <AccountButton />
        //       </Link>
        //     </AuthButton>
        //   </div>
        // }
      />
    </div>
  );
};

export default Header;
