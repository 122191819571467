import { createContext } from "react";
import { ResourceBookmark } from "Resource/types/Bookmark";
import { ResourceFilter } from "Resource/types/ResourceFilter";
import { ResourceTypeList } from "Resource/types/ResourceType";
import { Resource, ResourceList } from "Resource/types/Resource";

export type ResourceContextProps = {
  loading: boolean;
  finished: boolean;
  onNext: () => void;
  data?: ResourceList;
  getList: () => void;
  filter: ResourceFilter;
  types?: ResourceTypeList;
  bookmarks?: ResourceBookmark[];
  onBookmark: (e: Resource) => void;
  setFilter: (paylaod: ResourceFilter) => void;
};

const ResourceContext = createContext({} as ResourceContextProps);

export default ResourceContext;
