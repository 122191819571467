import Header from "./Header";
import styles from "./styles.module.scss";
import Footer from "Public/components/Footer";

const EulaPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <h1>ReliefScout End User License Agreement</h1>
        <p>
          This ReliefScout End User License Agreement (“<strong>Agreement</strong>”) is a binding agreement between you
          (“<strong>you</strong>”) and Modaramo Media, Inc. (“<strong>Company</strong>”). This Agreement governs your
          use of the ReliefScout application (including all related documentation, the “<strong>App</strong>”). In
          addition, by downloading, installing and/or using the App, you agree to be bound by our Terms of Use, located
          at{" "}
          <a href="https://www.reliefscout.com/tos" target="_blank" rel="noreferrer">
            https://www.reliefscout.com/tos
          </a>{" "}
          (the “<strong>Terms</strong>”).
        </p>
        <p>
          BY DOWNLOADING/INSTALLING/USING THE APP, YOU (A) ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND THIS AGREEMENT;
          (B) REPRESENT THAT YOU ARE 18 YEARS OF AGE OR OLDER/OF LEGAL AGE TO ENTER INTO A BINDING AGREEMENT; AND (C)
          ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS. IF YOU DO NOT AGREE TO THESE TERMS,
          DO NOT DOWNLOAD/INSTALL/USE THE APP AND DELETE IT FROM YOUR MOBILE DEVICE. THIS AGREEMENT REQUIRES THE USE OF
          ARBITRATION (ON AN INDIVIDUAL BASIS ONLY; I.E., CASE CONSOLIDATIONS AND CLASSACTIONS ARE NOT PERMITTED) IN
          ORDER TO RESOLVE DISPUTES.
        </p>
        <p>
          1. License Grant. Subject to the terms of this Agreement, Company grants you a limited, non-exclusive, and
          nontransferable license to download, install, and use the App for your personal, non-commercial use on a
          single mobile device owned or otherwise controlled by you (“<strong>Device</strong>”) strictly in accordance
          with the App’s documentation.
        </p>
        <p>
          2. License Restrictions. You shall not: (i) copy the App, except as expressly permitted by this Agreement;
          (ii) modify, translate, adapt, or otherwise create derivative works or improvements, whether or not
          patentable, of the App; (iii) reverse engineer, disassemble, decompile, decode, or otherwise attempt to derive
          or gain access to the source code of the App or any part thereof; (iv) remove, delete, alter, or obscure any
          trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices
          from the App, including any copy thereof; or (v) rent, lease, lend, sell, sublicense, assign, distribute,
          publish, transfer, or otherwise make available the App, or any features or functionality of the App, to any
          third party for any reason, including by making the App available on a network where it is capable of being
          accessed by more than one device at any time.
        </p>
        <p>
          3. Reservation of Rights. You acknowledge and agree that the App is provided under license, and not sold, to
          you. You do not acquire any ownership interest in the App under this Agreement, or any other rights thereto
          other than to use the App in accordance with the license granted, and subject to all terms, conditions, and
          restrictions, under this Agreement. Company and its licensors and service providers reserve and shall retain
          their entire right, title, and interest in and to the App, including all copyrights, trademarks, and other
          intellectual property rights therein or relating thereto, except as expressly granted to you in this
          Agreement.
        </p>
        <p>
          4. Collection and Use of Your Information. You acknowledge that when you download, install, or use the App,
          Company may use automatic means (including, for example, cookies and web beacons) to collect information about
          your Device and about your use of the App. You also may be required to provide certain information about
          yourself as a condition to downloading, installing, or using the App or certain of its features or
          functionality, and the App may provide you with opportunities to share information about yourself with others.
          All information we collect through or in connection with the App is subject to our Privacy Policy located at
          located at{" "}
          <a href="https://www.reliefscout.com/privacy" target="_blank" rel="noreferrer">
            https://www.reliefscout.com/privacy
          </a>{" "}
          (the “<strong>Privacy Policy</strong>”). By downloading, installing, using, and providing information to or
          through this App, you consent to all actions taken by us with respect to your information in compliance with
          the Privacy Policy.
        </p>
        <p>
          5. Updates. Company may from time to time in its sole discretion develop and provide App updates, which may
          include upgrades, bug fixes, patches, other error corrections, and/or new features (collectively, including
          related documentation, “<strong>Updates</strong>”). Updates may also modify or delete in their entirety
          certain features and functionality. You agree that Company has no obligation to provide any Updates or to
          continue to provide or enable any particular features or functionality. Based on your Device settings, when
          your Device is connected to the internet either: (i) the App will automatically download and install all
          available Updates; or (ii) you may receive notice of or be prompted to download and install available Updates.
          You shall promptly download and install all Updates and acknowledge and agree that the App or portions thereof
          may not properly operate should you fail to do so. You further agree that all Updates will be deemed part of
          the App and be subject to all terms and conditions of this Agreement.
        </p>
        <p>
          6. Third-Party Materials. The App may display, include, or make available third-party content (including data,
          information, applications, and other products, services, and/or materials) or provide links to third-party
          websites or services, including through third-party advertising (“<strong>Third-Party Materials</strong>”).
          You acknowledge and agree that Company is not responsible for Third-Party Materials, including their accuracy,
          completeness, timeliness, validity, copyright compliance, legality, decency, quality, or any other aspect
          thereof. Company does not assume and will not have any liability or responsibility to you or any other person
          or entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a
          convenience to you, and you access and use them entirely at your own risk and subject to such third parties'
          terms and conditions.
        </p>
        <p>
          7. Term and Termination. The term of this Agreement commences when you download, install and/or use the App
          and will continue in effect until terminated by you or Company as set forth in this Section 7. You may
          terminate this Agreement by deleting the App and all copies thereof from your Device. Company may terminate
          this Agreement at any time without notice if it ceases to support the App, which Company may do in its sole
          discretion or otherwise at its sole discretion. In addition, this Agreement will terminate immediately and
          automatically without any notice if you violate any of the terms and conditions of this Agreement. Upon
          termination: (i) all rights granted to you under this Agreement will also terminate; and (ii) you must cease
          all use of the App and delete all copies of the App from your Device and account. Termination will not limit
          any of Company’s rights or remedies at law or in equity.
        </p>
        <p>
          8. Disclaimer of Warranties. THE APP IS PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS AND WITH ALL
          FAULTS AND DEFECTS WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT PPROVIDED BY LAW, COMPANY, ON ITS OWN
          BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS, EXPRESSLY
          DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE APPLICATION,
          INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
          NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR
          TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
          REPRESENTS OR WARRANTS THAT THE APP WILL BE COMPATIBLE, OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS,
          SYSTEMS, OR SERVICES, WILL BE ACCURATE, COMPLETE, TIMELY, RELIABLE, SECURE, ERROR-FREE, OR UNINTERRUPTED, THAT
          DEFECTS WILL BE CORRECTED, THAT THE APP WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE APP
          OR ANY PRODUCTS, SERVICES OR ITEMS OBTAINED THROUGH THE APP WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAWS.
        </p>
        <p>
          9. Limitation of Liability. TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL COMPANY, ITS SUBSIDIARIES,
          AFFILIATES, AND ITS AND THEIR RESPECTIVE EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, MEMBERS, CONTRACTORS,
          LICENSORS, SERVICE PROVIDERS, SUPPLIERS, SUCCESSORS, AND ASSIGNS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
          LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE APP, ANY WEBSITES LINKED
          TO IT, ANY MATERIAL ON THE APP, OR ANY CONTENT ON SUCH OTHER LINKED WEBSITES, INCLUDING ANY DIRECT, INDIRECT,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, DEATH,
          PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED
          SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
          BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT
          BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
        </p>
        <p>
          10. Indemnification. You agree to defend, indemnify, and hold harmless the Company, its subsidiaries,
          affiliates, and its and their respective officers, directors, members, employees, contractors, agents,
          licensors, service providers, suppliers, successors, and assigns from and against any claims, liabilities,
          damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising
          out of or relating to: (a) your actual or alleged violation of this Agreement; or (b) your use or misuses of
          the App. The Company reserves the right to assume, at your sole expense, the exclusive defense and control of
          any matter that is subject to indemnification by you hereunder. In such event, you agree to cooperate fully at
          your sole expense with the Company in asserting, prosecuting or alleging any and all available defenses,
          counterclaims, cross-claims and any and all other pleadings, allegations or evidence and in all other respects
          at the Company’s written request at any time.
        </p>
        <p>
          11. Governing Law and Binding Arbitration. All matters relating to the App and this Agreement shall be
          interpreted under and governed by the substantive laws of the United States of America and the State of New
          York, without regard to principles of conflict of laws. You and the Company have agreed to this Agreement
          within the State of New York for all purposes. Subject to all applicable laws, you are agreeing to give up:
          (i) your right to litigate any claims that may arise hereunder in court or before a jury; and (ii) your right
          to consolidate any claim and/or participate in any class action claim that may arise hereunder in any manner
          or forum. Instead, any claim, dispute, or controversy of any kind or nature arising hereunder which cannot be
          amicably resolved by us shall be solely and finally settled by arbitration administered by the American
          Arbitration Association in accordance with its commercial arbitration rules. Judgment on the award rendered by
          the arbitrator(s) may be entered in any court having jurisdiction thereof. The arbitration shall take place
          before a panel of one arbitrator sitting in Nassau County, New York. The language of the arbitration shall be
          English. The arbitrator will be bound to adjudicate all disputes in accordance with the laws of the State of
          New York. The decision of the arbitrator(s) shall be in writing with written findings of fact and shall be
          final and binding on the parties. Each party shall bear all of its own costs, including but not limited to
          attorneys’ fees, actually incurred in connection with any such arbitration proceedings; provided, however,
          that if Company is the prevailing party, then it shall be entitled to reimbursement for its reasonable
          attorneys’ fees and related costs expended in connection with the arbitration. With respect to any arbitration
          hereunder, as stated above, you hereby expressly waive any right to consolidate any claim and/or participate
          in any class-action claim of any kind or nature. This Governing Law and Binding Arbitration section provides
          your sole recourse for the settlement of any disputes arising out of, in connection with, or related to this
          Agreement.
        </p>
        <p>
          12. General Terms. This Agreement, the Terms, the Privacy Policy and any other terms or conditions referenced
          herein or therein, constitute the entire agreement between you and the Company with respect to the matters
          herein and therein and supersede all prior and contemporaneous understandings, agreements, representations,
          and warranties, written and oral, between the Company and you. No action or inaction by the Company shall be
          construed as a waiver of this Agreement, the Terms, the Privacy Policy and any other terms or conditions
          referenced herein or therein. No waiver by the Company of any term or condition in this Agreement, the Terms,
          the Privacy Policy, or any other terms or conditions referenced herein or therein shall be deemed a further or
          continuing waiver of such term or condition or a waiver of any other term or condition. If any of the
          provisions of this Agreement, the Terms, the Privacy Policy, or any other terms or conditions referenced
          herein or therein are held to be invalid, unenforceable or illegal, such provision shall be eliminated or
          limited to the minimum extent such that the validity and enforceability of the remaining provisions of this
          Agreement, the Terms, the Privacy Policy, or any other terms or conditions referenced herein or therein shall
          not be effected thereby and shall continue in full force and effect.
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default EulaPage;
