import clone from "./clone";

type Props = (data: any, key: string, value: any) => any;

const set: Props = (data, key, value) => {
  let _data = clone(data);
  var schema = _data;
  const keys = key.split(".");
  const len = keys.length;

  for (let i = 0; i < len - 1; i++) {
    let elem = keys[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }

  schema[keys[len - 1]] = value;

  return _data;
};

export default set;
