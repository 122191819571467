import { useState } from "react";
import styles from "./styles.module.scss";
import useResource from "Resource/context";
import { EmptyOverlay } from "Core/components";
import useTranslate from "Core/context/translate";
import ResourceResultCard from "Resource/components/ResourceResultCard";

const ResourceBookmarks: React.FC = () => {
  const { t } = useTranslate();
  const resource = useResource();
  const [active, setActive] = useState<string>();

  const isActive = (e: string) => active === e;

  const handleOnClick = (e: string) => () => {
    setActive(e === active ? undefined : e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <EmptyOverlay
          title={t("resources.empty.title")}
          info={t("resources.empty.description")}
          isEmpty={resource.bookmarks?.length === 0}
        >
          {resource.bookmarks?.map((x, i) => (
            <ResourceResultCard
              key={i}
              value={x.data}
              active={isActive(x.data._id)}
              onClick={handleOnClick(x.data._id)}
            />
          ))}
        </EmptyOverlay>
      </div>
    </div>
  );
};
export default ResourceBookmarks;
