import styles from "./styles.module.scss";
import menuItems from "Public/constants/menuItems";

const NavigationMenu: React.FC = () => {
  const handleOnClick = (e: string) => () => {
    const element = document.getElementById(e);
    window.scrollTo({ behavior: "smooth", top: element?.offsetTop });
  };

  return (
    <nav className={styles.container}>
      {menuItems.map((x) => (
        <span key={x.value} onClick={handleOnClick(x.value)}>
          {x.label}
        </span>
      ))}
    </nav>
  );
};

export default NavigationMenu;
