import { createContext } from "react";
import { ProgramList } from "Program/types/Program";
import { ProgramListType } from "Program/types/ProgramList";

export type ProgramContextProps = {
  loading: boolean;
  data?: ProgramList;
  getList: () => void;
  type: ProgramListType;
  setType: (e: ProgramListType) => void;
};

const ProgramContext = createContext({} as ProgramContextProps);

export default ProgramContext;
