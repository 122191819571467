import api from "Core/api";
import { Profile } from "Profile/types/Profile";
import { Subprofile } from "Profile/types/Subprofile";
import { ProfileApi, SaveProfileResponse } from "Profile/types/ProfileApi";

const profileApi: ProfileApi = {
  saveProfile: async (profileId, id, answers) => {
    const response = await api.post<SaveProfileResponse>(
      `web-api/web/profile/${profileId}/subprofile/${id}/save`,
      answers
    );
    return response.data;
  },
  getProfiles: async () => {
    const response = await api.get<Profile[]>("web-api/web/profile/list");
    response.data = response.data.reduce((a, b) => {
      return b.percentage === 100 ? [...a, b] : [b, ...a];
    }, [] as Profile[]);
    return response.data;
  },
  getProfile: async (payload) => {
    const response = await api.get<Profile>(`web-api/web/profile/${payload}`);
    response.data.subprofiles = response.data.subprofiles.reduce((a, b) => {
      return b.percentage === 100 ? [...a, b] : [b, ...a];
    }, [] as Subprofile[]);
    return response.data;
  },
  getSubprofile: async (profileId, id) => {
    const response = await api.get<Subprofile>(`web-api/web/profile/${profileId}/subprofile/${id}`);
    response.data.answers = response.data.answers.map((x) => ({ ...x, subprofileID: id }));
    response.data.questions = response.data.questions.map((x) => ({ ...x, subprofileID: id }));
    return response.data;
  },

  updatePassword: async (payload) => {
    await api.post("web-api/web/info/password/update", payload);
    return;
  },
  updateProfile: async (payload) => {
    await api.post("web-api/web/info/save", payload);
    return;
  },
  updateAvatar: async (payload) => {
    await api.post("web-api/web/info/avatar/save", payload);
    return;
  },
};

export default profileApi;
