import { useState } from "react";
import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";
import useResource from "Resource/context";
import useTranslate from "Core/context/translate";
import { EmptyOverlay, InfiniteLoading } from "Core/components";
import ResourceFilterBar from "Resource/components/ResourceResultBar";
import ResourceResultCard from "Resource/components/ResourceResultCard";

const ResourceResult: React.FC = () => {
  const { t } = useTranslate();
  const resource = useResource();
  const [active, setActive] = useState<string>();

  const isActive = (e: string) => active === e;

  const handleOnClick = (e: string) => () => {
    setActive(e === active ? undefined : e);
  };

  useMounted(() => {
    resource.getList();
  });

  return (
    <div className={styles.container}>
      <ResourceFilterBar />
      <div className={styles.content}>
        <EmptyOverlay
          title={t("resources.empty.title")}
          isEmpty={resource.data?.length === 0}
          info={t("resources.empty.description")}
        >
          {resource.data?.map((x, i) => (
            <ResourceResultCard key={i} value={x} active={isActive(x._id)} onClick={handleOnClick(x._id)} />
          ))}
        </EmptyOverlay>
      </div>
      <InfiniteLoading loadNextPage={resource.onNext} loading={resource.loading} finished={resource.finished} />
    </div>
  );
};
export default ResourceResult;
