import profileApi from "Profile/api";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { useForm, useRequest } from "Core/hooks";
import useTranslate from "Core/context/translate";
import createPasswordForm from "Profile/utils/createPasswordForm";
import { Input, Form, Button, LoadingOverlay } from "Core/components";

const PasswordForm: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const form = useForm(createPasswordForm());
  const { request, loading } = useRequest(profileApi.updatePassword, { showError: true });

  const onSubmit = () => {
    request(form.state).then(() => {
      navigate(-1);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoadingOverlay loading={loading}>
          <Form form={form} onSubmit={onSubmit}>
            <Input
              type="password"
              {...form.field("currentPassword")}
              label={t("profile.edit.changePassword.currentPassword.label")}
              placeholder={t("profile.edit.changePassword.currentPassword.placeholder")}
            />
            <Input
              type="password"
              {...form.field("newPassword")}
              label={t("profile.edit.changePassword.newPassword.label")}
              placeholder={t("profile.edit.changePassword.newPassword.placeholder")}
            />
            <Input
              type="password"
              {...form.field("confirmPassword")}
              label={t("profile.edit.changePassword.confirmPassword.label")}
              placeholder={t("profile.edit.changePassword.confirmPassword.placeholder")}
            />
            <Button disabled={!form.valid}>{t("profile.edit.changePassword.submit")}</Button>
          </Form>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default PasswordForm;
