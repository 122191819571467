import { useState } from "react";
import resourceApi from "Resource/api";
import ResourceContext from "./context";
import { useGet, useRequest } from "Core/hooks";
import { ResourceFilter } from "Resource/types/ResourceFilter";
import { Resource, ResourceList } from "Resource/types/Resource";

const ResourceProvider: React.FC = ({ children }) => {
  const [finished, setFinished] = useState(false);
  const [data, setData] = useState<ResourceList>();
  const types = useGet(resourceApi.getResourceTypes);
  const bookmarks = useGet(resourceApi.getBookmarks);
  const { request, loading } = useRequest(resourceApi.getResources, { showError: true });
  const [filter, setFilter] = useState<ResourceFilter>({ limit: 50, offset: 0, isPrivate: true, types: [] });

  const getList = async (isPage?: boolean) => {
    if (!isPage) {
      setData(undefined);
      setFinished(false);
    } else {
      filter.offset += 50;
      setFilter(filter);
    }
    const response = await request(filter);
    setData(isPage ? [...(data || []), ...response] : response);
    if (response.length < 50) {
      setFinished(true);
    }
    return;
  };

  const onNext = () => {
    getList(true);
  };

  const onBookmark = async (e: Resource) => {
    const index = bookmarks.data?.findIndex((x) => x.data._id === e._id);
    if (index !== undefined && index > -1) {
      await resourceApi.deleteBookmark(bookmarks.data![index]._id);
    } else {
      await resourceApi.addBookmark(e);
    }
    bookmarks.request();
  };

  return (
    <ResourceContext.Provider
      value={{
        data,
        filter,
        onNext,
        loading,
        getList,
        finished,
        setFilter,
        onBookmark,
        types: types.data,
        bookmarks: bookmarks.data,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
};

export default ResourceProvider;
