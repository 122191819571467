import { Button } from "Core/components";
import styles from "./styles.module.scss";
import useResource from "Resource/context";
import useTranslate from "Core/context/translate";

const ResourceResultBar: React.FC = () => {
  const { t } = useTranslate();
  const { filter, setFilter, getList } = useResource();

  const handleOnClick = (e: string) => () => {
    const index = filter.types.indexOf(e);
    filter.types.splice(index, 1);
    setFilter({ ...filter, types: [...filter.types], offset: 0 });
    setTimeout(() => getList(), 500);
  };

  return filter.types.length > 0 ? (
    <div className={styles.container}>
      <div className={styles.content}>
        <label>{t("resources.result.filters")}:</label>
        <div className={styles.tags}>
          {filter.types.map((x) => (
            <span key={x} onClick={handleOnClick(x)}>
              {x}
              <Button type="light" icon="close" />
            </span>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ResourceResultBar;
