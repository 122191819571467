import styles from "./styles.module.scss";
import objectUtils from "Core/utils/object";
import { RadioGroup } from "Core/components";
import useProgram from "Program/context";
import programTypes from "Program/constants/programTypes";
import { ProgramListType } from "Program/types/ProgramList";
import useTranslate from "Core/context/translate";

const ProgramTypeSelect: React.FC = () => {
  const program = useProgram();
  const { t } = useTranslate();
  const options = objectUtils
    .asArray(programTypes)
    .map((x) => ({ label: t(`programs.tabs.${x.value.label}`), value: x.key }));

  const onChange = (e: string) => {
    program.setType(e as ProgramListType);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <RadioGroup value={program.type} onChange={onChange} options={options} />
      </div>
    </div>
  );
};

export default ProgramTypeSelect;
