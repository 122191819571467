import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Icon, Button } from "Core/components";
import { Program } from "Program/types/Program";
import { ProgramListType } from "Program/types/ProgramList";
import useTranslate from "Core/context/translate";

type Props = {
  value: Program;
  type: ProgramListType;
};

const ProgramCard: React.FC<Props> = ({ value, type }) => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <Link to={`/program/${value._id}`}>
        <div className={styles.content}>
          {type !== "visited" && <label>{t(`program.${type}`)}</label>}
          <img src={`data:image/png;base64, ${value.image?.data}`} alt="" />
          <div className={styles.innerContent}>
            <h4>{value.name}</h4>
            <p>{value.description}</p>
          </div>
          <Button>
            {t("program.learnMore")} <Icon name="chevronRight" />
          </Button>
        </div>
      </Link>
    </div>
  );
};

export default ProgramCard;
