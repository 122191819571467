import api from "Core/api";
import { ResourceTypeList } from "Resource/types/ResourceType";

type Props = () => Promise<ResourceTypeList>;

const getResourceTypes: Props = async () => {
  const response = await api.get<ResourceTypeList>("resource-api/web/lookup/types");
  return response.data;
};

export default getResourceTypes;
