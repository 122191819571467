import { FormEvent } from "react";
import { UseForm } from "Core/hooks/useForm";

export type Props = {
  form: UseForm;
  onSubmit: () => void;
};

const Form: React.FC<Props> = ({ children, onSubmit, form }) => {
  const onFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.valid ? onSubmit() : form.setSubmitted(true);
  };

  return <form onSubmit={onFormSubmit}>{children}</form>;
};

export default Form;
