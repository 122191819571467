import api from "Core/api";
import { ResourceList } from "Resource/types/Resource";
import { ResourceFilter } from "Resource/types/ResourceFilter";

type Props = (payload: ResourceFilter) => Promise<ResourceList>;

const getResources: Props = async (payload) => {
  const response = await api.post<ResourceList>(`resource-api/web/filter`, payload);
  return response.data;
};

export default getResources;
