import cx from "Core/utils/cx";
import styles from "./styles.module.scss";

type Props = {
  value: boolean;
  onChange: (e: boolean) => void;
};

const Checkbox: React.FC<Props> = ({ value, onChange, children }) => {
  const handleOnClick = () => {
    onChange(!value);
  };

  return (
    <div className={cx(styles.container, [styles.checked, value])}>
      <div className={styles.icon} onClick={handleOnClick} />
      <span>{children}</span>
    </div>
  );
};

export default Checkbox;
