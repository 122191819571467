import ProfileContext from ".";
import { useState } from "react";
import profileApi from "Profile/api";
import { useRequest } from "Core/hooks";
import { Profile } from "Profile/types/Profile";

const ProfileProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Profile[]>();
  const { request, loading } = useRequest(profileApi.getProfiles);

  const getList = () => {
    request().then(setData);
  };

  return <ProfileContext.Provider value={{ data, loading, getList }}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
