import Radiobox from "Core/components/Radiobox";
import { SurveyAnswer, SurveyOption, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const SingleOption: React.FC<Props> = ({ question, answer, onChange }) => {
  const isActive = (e: SurveyOption) => answer.answers.findIndex((x) => x._id === e._id) > -1;

  const handleOnChange = (x: SurveyOption) => () => {
    onChange({
      questionID: question._id,
      subprofileID: question.subprofileID,
      answers: [{ _id: x._id, value: x.value }],
    });
  };

  return (
    <div>
      {question.options
        .filter((x) => !x.invisible)
        .map((x) => (
          <Radiobox key={x._id} value={isActive(x)} onChange={handleOnChange(x)}>
            {x.text}
          </Radiobox>
        ))}
    </div>
  );
};

export default SingleOption;
