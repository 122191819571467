import styles from "./styles.module.scss";
import { Program } from "Program/types/Program";
import ProgramDetailIntro from "Program/components/ProgramDetailIntro";
import ProgramDetailSurvey from "Program/components/ProgramDetailSurvey";
import ProgramDetailButtons from "Program/components/ProgramDetailButtons";

type Props = {
  value?: Program;
  onResponse?: (e: string) => void;
};

const ProgramDetail: React.FC<Props> = ({ value, onResponse }) => {
  return (
    <div className={styles.container}>
      <ProgramDetailIntro value={value} />
      <div className={styles.content}>
        {value && (
          <>
            {value.missingQuestions.length > 0 ? (
              <ProgramDetailSurvey value={value} onResponse={onResponse} />
            ) : (
              <ProgramDetailButtons value={value} onResponse={onResponse} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProgramDetail;
