import { Fragment } from "react";
import programApi from "Program/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { Program } from "Program/types/Program";
import useTranslate from "Core/context/translate";
import { Button, OpenModal } from "Core/components";

type Props = {
  value: Program;
  onResponse?: (e: string) => void;
};

const ProgramDetailButtons: React.FC<Props> = ({ value, onResponse }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const applyReq = useRequest(programApi.apply, { showError: true });
  const phoneNumber = value.phoneNumber ? (value.phoneNumber.startsWith("tel:") ? "" : `tel:${value.phoneNumber}`) : "";

  const handleOnApply = () => {
    applyReq.request(value._id).then(() => {
      OpenModal({
        title: t("program.subprofile.apply.success.title"),
        cancel: t("program.subprofile.apply.success.cancel"),
        ok: t("program.subprofile.apply.success.newResources"),
        info: t("program.subprofile.apply.success.description"),
      })
        .then(() => {
          onResponse ? onResponse("resource") : navigate("/resource");
        })
        .catch(() => {
          onResponse ? onResponse("back") : navigate(-1);
        });
    });
  };

  const onWebsiteClick = () => {
    onResponse?.("website");
  };

  const onPhoneClick = () => {
    onResponse?.("phone");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!value.applied && value.qualified && !(!value.leadpost && value.websiteURL) && (
          <Button onClick={handleOnApply} loading={applyReq.loading}>
            {t("program.subprofile.apply")}
          </Button>
        )}
        {value.websiteURL ? (
          onResponse ? (
            <Button onClick={onWebsiteClick} icon="launch">
              {t("program.subprofile.visitWebsite")}
            </Button>
          ) : (
            <a href={value.websiteURL} target="_blank" rel="noreferrer">
              <Button icon="launch">{t("program.subprofile.visitWebsite")}</Button>
            </a>
          )
        ) : (
          <Fragment />
        )}
        {phoneNumber ? (
          onResponse ? (
            <Button onClick={onPhoneClick} icon="phone" type="danger">
              {t("program.subprofile.callPhone")}
            </Button>
          ) : (
            <a href={phoneNumber} target="_blank" rel="noreferrer">
              <Button icon="phone" type="danger">
                {t("program.subprofile.callPhone")}
              </Button>
            </a>
          )
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  );
};

export default ProgramDetailButtons;
