import ProgramProvider from "./context/provider";
import { Route, Routes } from "react-router-dom";
import ProgramListPage from "./pages/ProgramListPage";
import ProgramDetailPage from "./pages/ProgramDetailPage";

const Program: React.FC = () => {
  return (
    <ProgramProvider>
      <Routes>
        <Route element={<ProgramListPage />} index />
        <Route path=":id" element={<ProgramDetailPage />} />
      </Routes>
    </ProgramProvider>
  );
};

export default Program;
