import { SurveyAnswerList, SurveyQuestion, SurveyQuestionList } from "Core/types/Survey";

const getAnswer = (question: SurveyQuestion, answers: SurveyAnswerList) => {
  const item = answers.find((y) => y.questionID === question._id) || {
    answers: [],
    questionID: question._id,
    subprofileID: question.subprofileID,
  };
  return item;
};

const createAnswers = (questions: SurveyQuestionList) => {
  return questions.map((x) => ({ subprofileID: x.subprofileID, answers: [], questionID: x._id }));
};

const surveyUtils = {
  getAnswer,
  createAnswers,
};

export default surveyUtils;
