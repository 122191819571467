import { useState } from "react";
import objectUtils from "Core/utils/object";

export type Field = {
  value?: any;
  validate?: (value: any, values: any) => string;
};

export type UseFormPayload<T> = {
  [K in keyof T]: {
    value: T[K];
    validate?: (value: T[K], values: T) => string;
  };
};

export type UseForm = {
  field: (key: any) => {
    value: any;
    error: string | undefined;
    onChange: (e: any) => void;
  };
  state: any;
  valid: boolean;
  setSubmitted: (e: boolean) => void;
};

const createFields = <T extends Record<string, Field>>(fields: T): Record<keyof T, any> => {
  let state: Record<keyof T, any> = {} as Record<keyof T, any>;

  objectUtils.asArray(fields).forEach(({ key, value }) => {
    state[key] = value.value;
  });

  return state;
};

const useForm = <T>(fields: Record<keyof T, Field>) => {
  const [touched, setTouched] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [state, setState] = useState<Record<keyof T, any>>(createFields(fields));

  const valid = objectUtils.asArray(fields).reduce((a, b) => (!a ? a : !b.value.validate?.(state[b.key], state)), true);
  const hasEmptyField = !!objectUtils.asArray(state).find(({ value }) => !value);

  const onChange = (key: keyof T, value: any) => {
    state[key] = value;
    setState({ ...state });
    if (!touched) {
      setTouched(true);
    }
  };

  const set = (payload: Partial<T>) => {
    setState((prev) => ({ ...prev, ...payload }));
  };

  const field = (key: keyof T) => {
    return {
      value: state[key],
      onChange: (e: any) => onChange(key, e),
      error: submitted ? fields[key].validate?.(state[key], state) : "",
    };
  };

  const getError = (key: keyof T) => {
    return submitted ? fields[key].validate?.(state[key], state) : "";
  };

  const hasError = (key: keyof T) => {
    return submitted ? !!fields[key].validate?.(state[key], state) : false;
  };

  const reset = () => {
    setState(createFields(fields));
  };

  return { touched, field, valid, state, setSubmitted, set, hasError, getError, reset, hasEmptyField };
};

export default useForm;
