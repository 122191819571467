import { useRef, useState } from "react";
import useMounted from "Core/hooks/useMounted";
import useUpdated from "Core/hooks/useUpdated";

type Props = (callback: (top: number, prev: number) => void, render?: boolean) => void;

const useScroll: Props = (callback, render) => {
  const ref = useRef<number>(0);
  const [value, setValue] = useState(0);

  const onScroll = () => {
    if (render) {
      setValue(window.pageYOffset || document.documentElement.scrollTop);
    } else {
      callback(window.pageYOffset || document.documentElement.scrollTop, ref.current);
      ref.current = window.pageYOffset || document.documentElement.scrollTop;
    }
  };

  useMounted(() => {
    onScroll();
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  });

  useUpdated((e) => callback(e, ref.current), value);
};

export default useScroll;
