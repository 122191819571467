const menuItems = [
  {
    label: "Resources",
    value: "Resources",
  },
  {
    label: "How To",
    value: "HowTo",
  },
  {
    label: "Download",
    value: "Download",
  },
  {
    label: "FAQ",
    value: "FAQ",
  },
];

export default menuItems;
