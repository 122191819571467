import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";
import useTranslate from "Core/context/translate";
import useNotification from "Notification/context";
import { EmptyOverlay, LoadingOverlay } from "Core/components";
import NotificationListCard from "Notification/components/NotificationListCard";

const NotificationList: React.FC = () => {
  const { t } = useTranslate();
  const notification = useNotification();

  useMounted(() => {
    notification.getList();
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoadingOverlay loading={notification.loading}>
          <EmptyOverlay
            title={t("notifications.empty.title")}
            isEmpty={notification.data?.length === 0}
            info={t("notifications.empty.description")}
          >
            {notification.data?.map((x) => (
              <NotificationListCard key={x._id} value={x} />
            ))}
          </EmptyOverlay>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default NotificationList;
