import { useState } from "react";
// import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Button, Portal } from "Core/components";
// import AuthButton from "Auth/components/AuthButton";
import storeImages from "Public/constants/storeImages";
// import AccountButton from "Auth/components/AccountButton";
import NavigationMenu from "Public/components/NavigationMenu";

const MobileMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const handleOnClick = () => {
    setVisible(!visible);
  };

  const handleOnMenuClick = () => {
    setVisible(false);
  };

  return (
    <div className={styles.container}>
      <Button icon={visible ? "close" : "menu"} type="text" onClick={handleOnClick} />
      {visible && (
        <Portal container={document.getElementById("root")!}>
          <div className={styles.content}>
            {/* <AuthButton>
              <Link to="/program">
                <AccountButton />
              </Link>
            </AuthButton> */}
            <span onClick={handleOnMenuClick}>
              <NavigationMenu />
            </span>
            <div className={styles.download}>
              <h3>You can download at</h3>
              <div className={styles.buttons}>
                <img src={storeImages.appStore} className={styles.appStore} alt="App Store" />
                <img src={storeImages.googlePlay} className={styles.googlePlay} alt="Google Play" />
              </div>
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
};

export default MobileMenu;
