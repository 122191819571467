const icons = {
  arrowRight: () => import("Core/assets/icons/arrowRight.svg"),
  camera: () => import("Core/assets/icons/camera.svg"),
  check: () => import("Core/assets/icons/check.svg"),
  chevronUp: () => import("Core/assets/icons/chevronUp.svg"),
  chevronDown: () => import("Core/assets/icons/chevronDown.svg"),
  chevronLeft: () => import("Core/assets/icons/chevronLeft.svg"),
  chevronRight: () => import("Core/assets/icons/chevronRight.svg"),
  close: () => import("Core/assets/icons/close.svg"),
  loading: () => import("Core/assets/icons/loading.svg"),
  starOutlined: () => import("Core/assets/icons/starOutlined.svg"),
  starFilled: () => import("Core/assets/icons/starFilled.svg"),
  menu: () => import("Core/assets/icons/menu.svg"),
  rotateLeft: () => import("Core/assets/icons/rotateLeft.svg"),
  rotateRight: () => import("Core/assets/icons/rotateRight.svg"),
  zoomIn: () => import("Core/assets/icons/zoomIn.svg"),
  zoomOut: () => import("Core/assets/icons/zoomOut.svg"),
  notification: () => import("Core/assets/icons/notification.svg"),
  notificationFilled: () => import("Core/assets/icons/notificationFilled.svg"),
  program: () => import("Core/assets/icons/program.svg"),
  programFilled: () => import("Core/assets/icons/programFilled.svg"),
  resource: () => import("Core/assets/icons/resource.svg"),
  resourceFilled: () => import("Core/assets/icons/resourceFilled.svg"),
  "Youth Services": () => import("Core/assets/icons/youthServices.svg"),
  Disability: () => import("Core/assets/icons/disability.svg"),
  Education: () => import("Core/assets/icons/education.svg"),
  "Career/Training": () => import("Core/assets/icons/careerTraining.svg"),
  Financial: () => import("Core/assets/icons/financial.svg"),
  Food: () => import("Core/assets/icons/food.svg"),
  Housing: () => import("Core/assets/icons/housing.svg"),
  Legal: () => import("Core/assets/icons/legal.svg"),
  "Health Care": () => import("Core/assets/icons/healthCare.svg"),
  "Medical Assistance": () => import("Core/assets/icons/medicalAssistance.svg"),
  Social: () => import("Core/assets/icons/social.svg"),
  Veterans: () => import("Core/assets/icons/veterans.svg"),
  Women: () => import("Core/assets/icons/women.svg"),
  launch: () => import("Core/assets/icons/launch.svg"),
  phone: () => import("Core/assets/icons/phone.svg"),
};

export default icons;
