import { Header } from "Core/components";
import { useNavigate } from "react-router-dom";
import LogoutButton from "Auth/components/LogoutButton";
import ProfileList from "Profile/components/ProfileList";
import ProfileIntro from "Profile/components/ProfileIntro";
import NavigationMenu from "Profile/components/NavigationMenu";

const ProfileHomePage: React.FC = () => {
  const navigate = useNavigate();

  const onLeft = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header left={onLeft} center="Profile" right={<LogoutButton />} light />
      <ProfileIntro />
      <ProfileList />
      <NavigationMenu />
    </div>
  );
};

export default ProfileHomePage;
