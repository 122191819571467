import cx from "Core/utils/cx";
import { useState } from "react";
import { Icon } from "Core/components";
import styles from "./styles.module.scss";
import { Notification } from "Notification/types/Notification";
import NotificationListDrawer from "Notification/components/NotificationListDrawer";

type Props = {
  value: Notification;
};

const NotificationCard: React.FC<Props> = ({ value }) => {
  const [visible, setVisible] = useState(false);

  const onToggle = (e: boolean) => () => {
    setVisible(e);
  };

  return (
    <>
      <div className={cx(styles.container, [styles.active, !value.read])} onClick={onToggle(true)}>
        {value.imageUrl && <img src={value.imageUrl} alt={value.title} />}
        <div className={styles.content}>
          <h3>{value.title}</h3>
          <p>{value.body}</p>
        </div>
        <Icon name="chevronRight" />
      </div>
      <NotificationListDrawer visible={visible} onClose={onToggle(false)} value={value} />
    </>
  );
};
export default NotificationCard;
