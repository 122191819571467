import TextOption from "./TextOption";
import PhoneOption from "./PhoneOption";
import MultiOption from "./MultiOption";
import styles from "./styles.module.scss";
import SingleOption from "./SingleOption";
import LookupOption from "./LookupOption";
import LocationOption from "./LocationOption";
import { SurveyAnswer, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const Question: React.FC<Props> = ({ question, answer, onChange }) => {
  return (
    <section className={styles.container}>
      <h4>{question.text}</h4>
      <div className={styles.content}>
        {question.type === 1 && <SingleOption question={question} answer={answer} onChange={onChange} />}
        {question.type === 2 && <MultiOption question={question} answer={answer} onChange={onChange} />}
        {question.type === 3 && <TextOption question={question} answer={answer} onChange={onChange} />}
        {question.type === 4 && <LocationOption question={question} answer={answer} onChange={onChange} />}
        {question.type === 5 && <LookupOption question={question} answer={answer} onChange={onChange} />}
        {question.type === 6 && <PhoneOption question={question} answer={answer} onChange={onChange} />}
      </div>
    </section>
  );
};

export default Question;
