import { useGet, useUpdated } from "Core/hooks";
import programApi from "Program/api";
import Header from "Core/components/Header";
import { LoadingOverlay } from "Core/components";
import { useNavigate, useParams } from "react-router-dom";
import { ProgramDetailParams } from "Program/types/Params";
import ProgramDetail from "Program/components/ProgramDetail";
import ProgramTrustedForm from "Program/components/ProgramTrustedForm";

const ProgramDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<ProgramDetailParams>();
  const program = useGet(() => programApi.get(params.id!), { showError: true });

  const onLeft = () => {
    navigate(-1);
  };

  useUpdated(() => {
    if (program.data?.websiteURL && program.data.missingQuestions.length === 0) {
      window.open(program.data.websiteURL, "_blank");
    }
  }, program.data);

  return (
    <div>
      <LoadingOverlay loading={program.loading}>
        <Header left={onLeft} right={<></>} light />
        <ProgramDetail value={program.data} />
        <ProgramTrustedForm />
      </LoadingOverlay>
    </div>
  );
};

export default ProgramDetailPage;
