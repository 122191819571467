import authApi from "Auth/api";
import { useState } from "react";
import AuthContext from "./context";
import { useRequest } from "Core/hooks";
import Storage from "Core/utils/storage";
import { Account } from "Auth/types/Account";
import { useNavigate } from "react-router-dom";

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<Account>();
  const { request, loading } = useRequest(authApi.getAccount);

  const getData = async () => {
    try {
      const response = await request();
      setData(response);
      return;
    } catch (error) {
      Storage.deleteToken();
      throw error;
    }
  };

  const logout = () => {
    authApi.logout().then(() => {
      setData(undefined);
      navigate("/");
    });
  };

  return <AuthContext.Provider value={{ data, loading, getData, logout }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
