import { Header, NavigationMenu } from "Core/components";
import NotificationList from "Notification/components/NotificationList";

const NotificationListPage: React.FC = () => {
  return (
    <div>
      <Header center={<NavigationMenu active="notification" />} />
      <NotificationList />
    </div>
  );
};

export default NotificationListPage;
