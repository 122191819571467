import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import ReactPhoneInput from "react-phone-input-2";

type Props = {
  label: string;
  value: string;
  error?: string;
  placeholder?: string;
  onChange?: (e: string) => void;
};

const PhoneInput: React.FC<Props> = ({ label, placeholder, value, onChange, error }) => {
  return (
    <div className={cx(styles.container, [styles.error, !!error])}>
      <label>{label}</label>
      <ReactPhoneInput
        country="us"
        value={value}
        specialLabel=""
        onChange={onChange}
        disabled={!onChange}
        onlyCountries={["us"]}
        placeholder={placeholder}
        inputClass={cx([styles.filled, !!value])}
      />
      {error && <span>{error}</span>}
    </div>
  );
};

export default PhoneInput;
