import { Link } from "react-router-dom";
import { Button } from "Core/components";

const ResourceBookmarksButton: React.FC = () => {
  return (
    <Link to="/resource/bookmarks">
      <Button icon="starOutlined" type="text" />
    </Link>
  );
};

export default ResourceBookmarksButton;
