import { useEffect, useState } from "react";

type Props = (callback: () => void) => void;

const useMounted: Props = (callback) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      callback();
    }
  }, [mounted, callback]);
};

export default useMounted;
