import { createContext } from "react";
import { Account } from "Auth/types/Account";

export type AuthContextProps = {
  data?: Account;
  loading: boolean;
  logout: () => void;
  getData: () => Promise<void>;
};

const AuthContext = createContext({} as AuthContextProps);

export default AuthContext;
