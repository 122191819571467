import { Header } from "Core/components";
import { useNavigate } from "react-router-dom";
import ProfileForm from "Profile/components/ProfileForm";
import UpdateAvatar from "Profile/components/UpdateAvatar";
import useTranslate from "Core/context/translate";

const ProfileEditPage: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const onLeft = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header left={onLeft} center={t("profile.edit.title")} right={<></>} />
      <UpdateAvatar />
      <ProfileForm />
    </div>
  );
};

export default ProfileEditPage;
