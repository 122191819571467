import cx from "Core/utils/cx";
import { useState } from "react";
import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";

type Props = {
  drawer: boolean;
  visible: boolean;
  unmount: boolean;
  closable?: boolean;
  className?: string;
  onCancel: () => void;
  modalClassName?: string;
  filterClassName?: string;
};

const Content: React.FC<Props> = ({
  drawer,
  visible,
  unmount,
  closable,
  children,
  onCancel,
  className,
  modalClassName,
  filterClassName,
}) => {
  const [opened, setOpened] = useState(false);
  const flag = unmount ? opened : visible;

  const onClose = () => {
    setOpened(false);
    setTimeout(() => onCancel(), 200);
  };

  useMounted(() => {
    setTimeout(() => setOpened(true), 50);
  });

  return (
    <div className={cx(styles.container, [styles.drawer, drawer], [styles.opened, flag], modalClassName)}>
      <div className={cx(styles.content, className)}>
        {closable !== false && <Button className={styles.close} type="light" icon="close" onClick={onClose} />}
        {children}
      </div>
      <div className={cx(styles.filter, filterClassName)} onClick={onClose} />
    </div>
  );
};

export default Content;
