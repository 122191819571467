import api from "Core/api";
import { Notification } from "Notification/types/Notification";
import { NotificationApi } from "Notification/types/NotificationApi";

const notificationApi: NotificationApi = {
  getList: async () => {
    const response = await api.post<Notification[]>(`notification-api/web/list`, { limit: 0, offset: 0 });
    return response.data;
  },
  get: async (id) => {
    const response = await api.get<Notification>(`notification-api/web/${id}`);
    return response.data;
  },
  delete: async (id) => {
    await api.delete(`notification-api/web/${id}`);
    return;
  },
  markRead: async (id) => {
    await api.get(`notification-api/web/markread/${id}`);
    return;
  },
};

export default notificationApi;
