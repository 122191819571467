import api from "Core/api";
import Storage from "Core/utils/storage";
import { Account } from "Auth/types/Account";
import { AuthApi, LoginResponse } from "Auth/types/AuthApi";

const authApi: AuthApi = {
  getAccount: async () => {
    const response = await api.get<Account>("web-api/web/info");
    return response.data;
  },
  login: async (payload) => {
    const response = await api.post<LoginResponse>("web-api/web/login", { ...payload });
    api.defaults.headers.common["Authorization"] = `Bearer ${response.data.accessToken}`;
    Storage.setToken(response.data.accessToken, response.data.refreshToken);
    return;
  },
  register: async (payload) => {
    const response = await api.post<LoginResponse>("web-api/web/signup", { ...payload });
    api.defaults.headers.common["Authorization"] = `Bearer ${response.data.accessToken}`;
    Storage.setToken(response.data.accessToken, response.data.refreshToken);
    return;
  },
  forgotPassword: async (payload) => {
    await api.post("web-api/web/password/reset", payload);
    return;
  },
  resetPassword: async (payload) => {
    await api.post("web-api/web/password/update", payload);
    return;
  },
  verifyPin: async (payload) => {
    await api.get(`web-api/web/password/verify/${payload}`);
    return;
  },
  logout: async () => {
    delete api.defaults.headers.common["Authorization"];
    Storage.deleteToken();
    return;
  },
};

export default authApi;
