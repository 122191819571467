import styles from "./styles.module.scss";
import iphoneImage from "Public/assets/iphone.png";
import androidImage from "Public/assets/android.png";
import storeImages from "Public/constants/storeImages";

const Download: React.FC = () => {
  return (
    <section id="Download" className={styles.container}>
      <div className={styles.content}>
        <img className={styles.image} src={iphoneImage} alt="iPhone" />
        <div className={styles.innerContent}>
          <h4>Download NOW!</h4>
          <p>
            Never miss out on new opportunities. Download the app today so you can always be the first to apply to the
            help you need.
          </p>
          <div className={styles.buttons}>
            <a href="https://apps.apple.com/us/app/reliefscout/id1573777513" target="_blank" rel="noreferrer">
              <img src={storeImages.appStore} className={styles.appStore} alt="App Store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.modaramo.reliefscout"
              target="_blank"
              rel="noreferrer"
            >
              <img src={storeImages.googlePlay} className={styles.googlePlay} alt="Google Play" />
            </a>
          </div>
        </div>
        <img className={styles.image} src={androidImage} alt="Android" />
      </div>
    </section>
  );
};

export default Download;
