import { useGet } from "Core/hooks";
import profileApi from "Profile/api";
import { SubprofileParams } from "Profile/types/Params";
import { Header, LoadingOverlay } from "Core/components";
import { useNavigate, useParams } from "react-router-dom";
import SubprofileSurvey from "Profile/components/SubprofileSurvey";

const ProfileSubprofileDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<SubprofileParams>();
  const subprofile = useGet(() => profileApi.getSubprofile(params.profileId!, params.id!), { showError: true });

  const onLeft = () => {
    navigate(-1);
  };

  return (
    <div>
      <LoadingOverlay loading={subprofile.loading}>
        <Header left={onLeft} center={subprofile.data?.name || ""} right={<></>} />
        {subprofile.data && <SubprofileSurvey value={subprofile.data} profileId={params.profileId!} />}
      </LoadingOverlay>
    </div>
  );
};

export default ProfileSubprofileDetailPage;
