import api from "Core/api";

type Props = (payload: string) => Promise<void>;

const deleteBookmark: Props = async (payload) => {
  await api.delete(`resource-api/web/bookmark/${payload}`);
  return;
};

export default deleteBookmark;
