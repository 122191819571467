import Checkbox from "Core/components/Checkbox";
import { SurveyAnswer, SurveyOption, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const MultiOption: React.FC<Props> = ({ question, answer, onChange }) => {
  const isActive = (e: SurveyOption) => answer.answers.findIndex((x) => x._id === e._id) > -1;

  const handleOnChange = (x: SurveyOption) => (e: boolean) => {
    if (e) {
      answer.answers.push({ _id: x._id, value: x.value });
    } else {
      const index = answer.answers.findIndex((y) => y._id === x._id);
      answer.answers.splice(index, 1);
    }
    onChange({ questionID: question._id, subprofileID: question.subprofileID, answers: answer.answers });
  };

  return (
    <div>
      {question.options
        .filter((x) => !x.invisible)
        .map((x) => (
          <Checkbox key={x._id} value={isActive(x)} onChange={handleOnChange(x)}>
            {x.text}
          </Checkbox>
        ))}
    </div>
  );
};

export default MultiOption;
