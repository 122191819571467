import Content from "./Content";
import { useState } from "react";
import Portal from "Core/components/Portal";
import { useMounted, useUpdated } from "Core/hooks";

type Props = {
  visible: boolean;
  drawer?: boolean;
  unmount?: boolean;
  closable?: boolean;
  className?: string;
  onClose: () => void;
  modalClassName?: string;
  filterClassName?: string;
};

const Modal: React.FC<Props> = ({
  onClose,
  visible,
  children,
  closable,
  className,
  modalClassName,
  filterClassName,
  drawer = false,
  unmount = true,
}) => {
  const [mounted, setMounted] = useState(false);

  const toggleStyle = (e: boolean) => {
    document.getElementsByTagName("html")[0].style.overflow = e ? "hidden" : "";
    document.getElementsByTagName("body")[0].style.overflow = e ? "hidden" : "";
  };

  const onCanceled = () => {
    toggleStyle(false);
    onClose();
  };

  useUpdated(() => {
    toggleStyle(visible);
  }, visible);

  useMounted(() => {
    setMounted(true);
    toggleStyle(false);
  });

  return mounted && (!unmount || (unmount && visible)) ? (
    <Portal container={document.getElementsByTagName("body")[0]}>
      <Content
        drawer={drawer}
        unmount={unmount}
        visible={visible}
        closable={closable}
        children={children}
        onCancel={onCanceled}
        className={className}
        modalClassName={modalClassName}
        filterClassName={filterClassName}
      />
    </Portal>
  ) : (
    <></>
  );
};

export default Modal;
