import Input from "Core/components/Input";
import { SurveyAnswer, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const LocationOption: React.FC<Props> = ({ question, answer, onChange }) => {
  const value = String(answer.answers[0].value || "");

  const handleOnChange = (e: string) => {
    onChange({ questionID: question._id, subprofileID: question.subprofileID, answers: [{ _id: "", value: e }] });
  };

  return (
    <div>
      <Input label={question.text} placeholder="Please Enter" value={value} onChange={handleOnChange} />
    </div>
  );
};

export default LocationOption;
