import { useScroll } from "Core/hooks";
import Icon from "Core/components/Icon";
import styles from "./styles.module.scss";

type Props = {
  loading: boolean;
  finished: boolean;
  loadNextPage: () => void;
};

const InfiniteLoading: React.FC<Props> = ({ loading, finished, loadNextPage }) => {
  useScroll(() => {
    if (!loading && !finished) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 80) {
        loadNextPage();
      }
    }
  }, true);

  return loading ? (
    <div className={styles.container}>
      <Icon name="loading" />
    </div>
  ) : (
    <></>
  );
};

export default InfiniteLoading;
