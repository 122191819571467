import useMounted from "./useMounted";
import { useRef, useEffect, useState } from "react";

type Callback<T> = (current: T, previous?: T) => void;

const useUpdated = <T>(callback: Callback<T>, props: T) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<T>();

  useMounted(() => {
    ref.current = props;
    setMounted(true);
  });

  useEffect(() => {
    if (mounted) {
      if (JSON.stringify(props) !== JSON.stringify(ref.current)) {
        const current = props;
        const prev = ref.current;
        callback && callback(current, prev);
        ref.current = props;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
};

export default useUpdated;
