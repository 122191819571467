import { useMounted } from "Core/hooks";
import useProgram from "Program/context";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import useTranslate from "Core/context/translate";
import { EmptyOverlay, LoadingOverlay } from "Core/components";
import ProgramListCard from "Program/components/ProgramListCard";

const ProgramList: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const program = useProgram();

  const handleOnClick = () => {
    navigate("/profile");
  };

  useMounted(() => {
    program.getList();
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoadingOverlay loading={program.loading}>
          <EmptyOverlay
            onClick={handleOnClick}
            title={t("program.empty.title")}
            isEmpty={program.data?.length === 0}
            info={t("program.empty.description")}
            button={t("program.empty.toProfile")}
          >
            {program.data?.map((x) => (
              <ProgramListCard key={x._id} value={x} type={program.type} />
            ))}
          </EmptyOverlay>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default ProgramList;
