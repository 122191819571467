import styles from "./styles.module.scss";
import IconUrl from "Core/assets/icon.svg";
import IntroUrl from "Public/assets/intro.png";
// import AuthButton from "Auth/components/AuthButton";

const Intro: React.FC = () => {
  return (
    <section id="Intro" className={styles.container}>
      <div className={styles.content}>
        <img className={styles.icon} src={IconUrl} alt="ReliefScout" />
        <h1>Get Connected To The Resources You Need</h1>
        <h2>Sign up for free to access available programs that can help you achieve more.</h2>
        {/* <div className={styles.buttons}>
          <AuthButton />
        </div> */}
        <h3>
          Finding <span>assistance</span> has never been easier!
        </h3>
        <img src={IntroUrl} alt="" />
      </div>
    </section>
  );
};

export default Intro;
