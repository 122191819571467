import ReactDOM from "react-dom";

type Props = {
  container: Element;
};

const Portal: React.FC<Props> = ({ children, container }) => {
  return container ? ReactDOM.createPortal(children, container) : null;
};
export default Portal;
