import profileApi from "Profile/api";
import styles from "./styles.module.scss";
import useAuth from "Auth/context";
import { useNavigate } from "react-router-dom";
import { useForm, useRequest } from "Core/hooks";
import useTranslate from "Core/context/translate";
import createProfileForm from "Profile/utils/createProfileForm";
import { Input, Form, Button, LoadingOverlay } from "Core/components";

const ProfileForm: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslate();
  const navigate = useNavigate();
  const form = useForm(createProfileForm(auth.data));
  const { request, loading } = useRequest(profileApi.updateProfile, { showError: true });

  const handleOnPassword = () => {
    navigate("/profile/password");
  };

  const onSubmit = () => {
    request(form.state).then(() => {
      auth.getData();
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LoadingOverlay loading={loading}>
          <Form form={form} onSubmit={onSubmit}>
            <p>{t("profile.edit.description")}</p>
            <Input
              {...form.field("email")}
              label={t("profile.edit.email.label")}
              placeholder={t("profile.edit.email.placeholder")}
            />
            <div className={styles.password}>
              <Input value="******" label={t("profile.edit.password.label")} />
              <Button type="link" onClick={handleOnPassword}>
                Change Password
              </Button>
            </div>
            <Input
              {...form.field("zip")}
              label={t("profile.edit.zip.label")}
              placeholder={t("profile.edit.zip.placeholder")}
            />
            <div className={styles.row}>
              <Input
                {...form.field("name")}
                label={t("profile.edit.firstName.label")}
                placeholder={t("profile.edit.firstName.placeholder")}
              />
              <Input
                {...form.field("surname")}
                label={t("profile.edit.lastName.label")}
                placeholder={t("profile.edit.lastName.placeholder")}
              />
            </div>
            <Button htmlType="submit" disabled={!form.valid}>
              {t("profile.edit.changePassword.submit")}
            </Button>
          </Form>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default ProfileForm;
