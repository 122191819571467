import cx from "Core/utils/cx";
import styles from "./styles.module.scss";

type Props = {
  percent: number;
  className?: string;
};

const Progress: React.FC<Props> = ({ className, percent }) => {
  const radius = 45;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * percent) / 100;
  const color = percent < 25 ? "default" : percent < 50 ? "red" : percent < 75 ? "yellow" : "green";

  return (
    <svg className={cx(styles.container, styles[color], className)} width="1em" height="1em" viewBox="0 0 100 100">
      <circle className={styles.background} cx={50} cy={50} r={45} strokeWidth={10} />
      <circle
        r={45}
        cx={50}
        cy={50}
        strokeWidth={10}
        className={styles.progress}
        transform="rotate(-90 50 50)"
        style={{ strokeDasharray: dashArray, strokeDashoffset: dashOffset }}
      />
      <text className={styles.label} x="50%" y="35%" dy=".3em" textAnchor="middle">
        %
      </text>
      <text className={styles.percent} x="50%" y="62%" dy=".3em" textAnchor="middle">
        {percent}
      </text>
    </svg>
  );
};

export default Progress;
