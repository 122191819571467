import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { Modify } from "Core/types/Modify";
import { useOuterClick } from "Core/hooks";
import { HTMLProps, useRef, useState } from "react";

type CustomProps = {
  value: string;
  label?: string;
  placeholder?: string;
  onChange?: (e: any) => void;
  options: { label: string; value: any }[];
};

type Props = Modify<HTMLProps<HTMLInputElement>, CustomProps>;

const Select: React.FC<Props> = ({ label, placeholder, value, onChange, options }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState(false);
  const selected = options.find((x) => x.value === value);
  const isSelected = (e: string) => selected?.value === e;

  const handleOnOpen = () => {
    setVisible(true);
  };

  const handleOnChange = (e: any) => () => {
    onChange?.(e);
    setVisible(false);
  };

  useOuterClick(() => {
    setVisible(false);
  }, ref);

  return (
    <div className={cx(styles.container)} ref={ref}>
      <label>{label}</label>
      <div className={cx(styles.content, [styles.filled, !!value])} onClick={handleOnOpen}>
        {selected?.label || placeholder}
      </div>
      <div className={cx(styles.menu, [styles.visible, visible])}>
        {options.map((x) => (
          <div
            key={x.value}
            onClick={handleOnChange(x.value)}
            className={cx(styles.item, [styles.active, isSelected(x.value)])}
          >
            {x.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Select;
