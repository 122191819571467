import PhoneInput from "Core/components/PhoneInput";
import { SurveyAnswer, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const PhoneOption: React.FC<Props> = ({ question, answer, onChange }) => {
  const value = String(answer.answers[0]?.value || "");

  const handleOnChange = (e: string) => {
    onChange({ questionID: question._id, subprofileID: question.subprofileID, answers: [{ _id: "", value: e }] });
  };

  return (
    <div>
      <PhoneInput label={question.text} value={value} onChange={handleOnChange} />
    </div>
  );
};

export default PhoneOption;
