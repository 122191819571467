import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import { Subprofile } from "Profile/types/Subprofile";
import ProfileCard from "Profile/components/ProfileCard";
import useTranslate from "Core/context/translate";

type Props = {
  id: string;
  value?: Subprofile[];
};

const SubprofileList: React.FC<Props> = ({ value, id }) => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>{t("profile.profiles.detail.info")}</p>
        {value?.map((x) => (
          <Link key={x._id} to={`/profile/${id}/subprofile/${x._id}`}>
            <ProfileCard title={x.name} info={x.description} percent={x.percentage} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SubprofileList;
