import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import LogoUrl from "Core/assets/logo.svg";
import storeImages from "Public/constants/storeImages";

const Footer: React.FC = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <img className={styles.logo} alt="ReliefScout" src={LogoUrl} />
          <p>You can download at</p>
          <a href="https://apps.apple.com/us/app/reliefscout/id1573777513" target="_blank" rel="noreferrer">
            <img src={storeImages.appStore} className={styles.appStore} alt="App Store" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.modaramo.reliefscout"
            target="_blank"
            rel="noreferrer"
          >
            <img src={storeImages.googlePlay} className={styles.googlePlay} alt="Google Play" />
          </a>
        </div>
        <div className={styles.right}>
          {/* <nav>
            <label>Resources</label>
            <span>Sign In</span>
            <span>Sign Up</span>
          </nav> */}
          <nav>
            <label>Platform</label>
            <span>
              <Link to="/tos">Terms & Conditions</Link>
            </span>
            <span>
              <Link to="/eula">End-User Agreement</Link>
            </span>
            <span>
              <Link to="/privacy">Privacy</Link>
            </span>
            <span>FAQ</span>
            <span>
              Contact Us: <a href="mailto:info@reliefscout.com">info@reliefscout.com</a>
            </span>
          </nav>
        </div>
      </div>
      <div className={styles.copyright}>© 2022 ReliefScout · All Right Reserved</div>
    </footer>
  );
};

export default Footer;
