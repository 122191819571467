import Select from "Core/components/Select";
import { SurveyAnswer, SurveyQuestion } from "Core/types/Survey";

type Props = {
  answer: SurveyAnswer;
  question: SurveyQuestion;
  onChange: (e: SurveyAnswer) => void;
};

const LookupOption: React.FC<Props> = ({ question, answer, onChange }) => {
  const active = answer.answers && answer.answers.length > 0 ? answer.answers[0]._id : "";

  const handleOnChange = (x: string) => {
    const item = question.options.find((y) => y._id === x);
    if (item) {
      onChange({
        questionID: question._id,
        subprofileID: question.subprofileID,
        answers: [{ _id: item._id, value: item.value }],
      });
    }
  };

  return (
    <div>
      <Select
        value={active}
        label={question.text}
        onChange={handleOnChange}
        placeholder={question.text}
        options={question.options
          .filter((x) => !x.invisible)
          .map((x) => ({
            label: x.text,
            value: x._id,
          }))}
      />
    </div>
  );
};

export default LookupOption;
