import Faq from "Public/components/Faq";
import Intro from "Public/components/Intro";
import Header from "Public/components/Header";
import Footer from "Public/components/Footer";
import Profile from "Public/components/Profile";
import Download from "Public/components/Download";
import Resource from "Public/components/Resource";
// import AuthModal from "Auth/components/AuthModal";

const HomePage: React.FC = () => {
  return (
    <div>
      {/* <AuthModal /> */}
      <Header />
      <Intro />
      <Resource />
      <Profile />
      <Download />
      <Faq />
      <Footer />
    </div>
  );
};
export default HomePage;
