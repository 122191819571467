import api from "Core/api";
import { useState } from "react";
import { useMounted } from "Core/hooks";
import TranslateContext from "./context";
import { LoadingOverlay } from "Core/components";

const TranslateProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<Record<string, string>>();

  const t = (e: string) => {
    return data?.[e] || e;
  };

  useMounted(() => {
    api.get("web-api/web/translation/en").then((response) => {
      setData(response.data);
    });
  });

  return (
    <TranslateContext.Provider value={{ t }}>{data ? children : <LoadingOverlay loading />}</TranslateContext.Provider>
  );
};

export default TranslateProvider;
