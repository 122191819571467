import { createContext } from "react";
import { NotificationList } from "Notification/types/Notification";

export type NotificationContextProps = {
  loading: boolean;
  getList: () => void;
  data?: NotificationList;
  onRead: (id: string) => void;
  onDelete: (id: string) => void;
};

const NotificationContext = createContext({} as NotificationContextProps);

export default NotificationContext;
