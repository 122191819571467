import addBookmark from "./addBookmark";
import getBookmark from "./getBookmark";
import getBookmarks from "./getBookmarks";
import getResources from "./getResources";
import deleteBookmark from "./deleteBookmark";
import getResourceTypes from "./getResourceTypes";

const resourceApi = {
  addBookmark,
  getBookmark,
  getBookmarks,
  getResources,
  deleteBookmark,
  getResourceTypes,
};

export default resourceApi;
