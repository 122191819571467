import { useGet } from "Core/hooks";
import programApi from "Program/api";
import { Fragment, useState } from "react";
import { LoadingOverlay } from "Core/components";
import { ProgramDetailParams } from "Program/types/Params";
import ProgramDetail from "Program/components/ProgramDetail";
import { useParams, useSearchParams } from "react-router-dom";
import ProgramTrustedForm from "Program/components/ProgramTrustedForm";

const TrustedFormProgramPage: React.FC = () => {
  const [query] = useSearchParams();
  const [token] = useState(query.get("t"));
  const params = useParams<ProgramDetailParams>();
  const program = useGet(() => programApi.getPublic(params.id!, token!));

  const onResponse = (type: string) => {
    TrustedForm.postMessage(type);
  };

  return token ? (
    <div>
      <LoadingOverlay loading={program.loading}>
        <ProgramDetail value={program.data} onResponse={onResponse} />
        <ProgramTrustedForm />
      </LoadingOverlay>
    </div>
  ) : (
    <Fragment />
  );
};

export default TrustedFormProgramPage;
