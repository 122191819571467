import api from "Core/api";
import { ResourceBookmark } from "Resource/types/Bookmark";

type Props = (payload: string) => Promise<ResourceBookmark>;

const getBookmark: Props = async (payload) => {
  const response = await api.get(`resource-api/web/bookmark/${payload}`);
  return response.data;
};

export default getBookmark;
