import { UseFormPayload } from "Core/hooks/useForm";
import { UpdateProfileForm } from "Profile/types/ProfileForm";
import { validateEmail, validateRequired } from "Core/utils/validators";

type Props = (payload?: Partial<UpdateProfileForm>) => UseFormPayload<UpdateProfileForm>;

const createProfileForm: Props = (payload) => {
  return {
    email: {
      value: payload?.email || "",
      validate: validateEmail("Email"),
    },
    name: {
      value: payload?.name || "",
    },
    surname: {
      value: payload?.surname || "",
    },
    zip: {
      value: payload?.zip || "",
      validate: validateRequired("Zip Code"),
    },
  };
};

export default createProfileForm;
