import icons from "./icons";
import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { IconName } from "Core/types/Icon";
import { useEffect, useState } from "react";

type IconElement = React.FC<{ className?: string }>;

type Props = {
  name: IconName;
  className?: string;
};

const Icon: React.FC<Props> = ({ name, className }) => {
  const [mounted, setMounted] = useState(false);
  const [Element, setElement] = useState<IconElement>();

  useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (mounted) {
      icons[name]().then((response) => {
        setElement(response.ReactComponent);
      });
    }
  }, [name, mounted]);

  return <>{Element && <Element className={cx(styles.container, className)} />}</>;
};

export default Icon;
