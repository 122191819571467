type Props = {
  deleteToken: () => void;
  delete: (key: string) => void;
  getToken: () => string | undefined;
  get: (key: string) => string | undefined;
  setToken: (token: string, refreshToken: string) => void;
  set: (key: string, value: string | string[] | object | undefined) => void;
};

const Storage: Props = {
  get: (key) => {
    return localStorage.getItem(key) || "";
  },
  set: (key, value) => {
    localStorage.setItem(key, typeof value !== "string" ? JSON.stringify(value) : value);
  },
  delete: (key) => {
    localStorage.removeItem(key);
  },
  getToken: () => {
    return localStorage.getItem("token") || "";
  },
  setToken: (token, refreshToken) => {
    localStorage.setItem("token", token);
    localStorage.setItem("refreshtoken", refreshToken);
  },
  deleteToken: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
  },
};

export default Storage;
