import Public from "Public";
import Profile from "Profile";
import Program from "Program";
import Resources from "Resource";
import RootLayout from "./layout";
import Notification from "Notification";
import { Routes, Route } from "react-router-dom";

const Root: React.FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Public />} />
      <Route element={<RootLayout />}>
        <Route path="/program/*" element={<Program />} />
        <Route path="/resource/*" element={<Resources />} />
        <Route path="/notification/*" element={<Notification />} />
        <Route path="/profile/*" element={<Profile />} />
      </Route>
    </Routes>
  );
};

export default Root;
