import cx from "Core/utils/cx";
import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import LogoUrl from "Core/assets/logo.svg";
import { Fragment, useState } from "react";
import useScroll from "Core/hooks/useScroll";
import CoreHeader from "Core/components/Header";

const Header: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);

  useScroll((top) => {
    setScrolled(top > 60);
  });

  return (
    <div className={cx(styles.container, [styles.scrolled, scrolled])}>
      <CoreHeader
        left={<Fragment />}
        center={
          <Link to="/">
            <img src={LogoUrl} alt="ReliefScout" />
          </Link>
        }
      />
    </div>
  );
};

export default Header;
