import cx from "Core/utils/cx";
import { Icon } from "Core/components";
import styles from "./styles.module.scss";
import useResource from "Resource/context";
import { IconName } from "Core/types/Icon";

const ResourceFilterType: React.FC = () => {
  const { types, filter, setFilter } = useResource();

  const isActive = (e: string) => filter.types.indexOf(e) > -1;

  const handleOnClick = (e: string) => () => {
    const index = filter.types.indexOf(e);
    index > -1 ? filter.types.splice(index, 1) : filter.types.push(e);
    setFilter({ ...filter, types: [...filter.types] });
  };

  return (
    <div className={styles.container}>
      {types?.map((x) => (
        <div key={x} className={cx(styles.content, [styles.active, isActive(x)])} onClick={handleOnClick(x)}>
          <Icon name={x as IconName} />
          <span>{x}</span>
        </div>
      ))}
    </div>
  );
};

export default ResourceFilterType;
