import { useGet } from "Core/hooks";
import profileApi from "Profile/api";
import { ProfileParams } from "Profile/types/Params";
import { Header, LoadingOverlay } from "Core/components";
import { useNavigate, useParams } from "react-router-dom";
import SubprofileList from "Profile/components/SubprofileList";

const ProfileDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams<ProfileParams>();
  const profile = useGet(() => profileApi.getProfile(params.id!), { showError: true });

  const onLeft = () => {
    navigate(-1);
  };

  return (
    <div>
      <LoadingOverlay loading={profile.loading}>
        <Header left={onLeft} center={profile.data?.name || ""} right={<></>} />
        <SubprofileList value={profile.data?.subprofiles} id={params.id!} />
      </LoadingOverlay>
    </div>
  );
};

export default ProfileDetailPage;
