import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import useResource from "Resource/context";
import { MouseEvent, useState } from "react";
import { Button, Icon } from "Core/components";
import useTranslate from "Core/context/translate";
import { Resource } from "Resource/types/Resource";

type Props = {
  value: Resource;
  active: boolean;
  onClick: () => void;
};

const ResourceResultCard: React.FC<Props> = ({ value, active, onClick }) => {
  const { t } = useTranslate();
  const resource = useResource();
  const [error, setError] = useState(false);
  const isBookmarked = !!resource.bookmarks?.find((x) => x.data._id === value._id);

  const onError = () => {
    setError(true);
  };

  const onBookmark = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    resource.onBookmark(value);
  };

  return (
    <div className={cx(styles.container, [styles.active, active])} onClick={onClick}>
      <div className={styles.icon}>
        {!error && value.logoUrl ? (
          <img src={value.logoUrl} alt={value.name} onError={onError} />
        ) : (
          <Icon name="Housing" />
        )}
      </div>
      <div className={styles.content}>
        <h3>{value.name}</h3>
        <p>{value.description}</p>
        <section>
          {value.website && (
            <a href={value.website} target="_blank" rel="noreferrer">
              <Button>{t("resources.result.detail.continue")}</Button>
            </a>
          )}
          <Button
            type="text"
            onClick={onBookmark}
            className={styles.bookmark}
            icon={isBookmarked ? "starFilled" : "starOutlined"}
          />
        </section>
      </div>
      <Icon name="chevronDown" className={styles.arrow} />
    </div>
  );
};

export default ResourceResultCard;
