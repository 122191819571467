import api from "Core/api";
import { Program } from "Program/types/Program";
import { ProgramApi } from "Program/types/ProgramApi";

const programApi: ProgramApi = {
  getNewList: async () => {
    const response = await api.get<Program[]>(`web-api/web/program/new`);
    return response.data;
  },
  getSubmittedList: async () => {
    const response = await api.get<Program[]>(`web-api/web/program/list/applied`);
    return response.data;
  },
  getVisitedList: async () => {
    const response = await api.get<Program[]>(`web-api/web/program/list/visited`);
    return response.data;
  },
  complete: async (id, answers) => {
    await api.post(`web-api/web/program/${id}/complete`, {
      subscriberAnswers: answers,
      trustedToken: (window as any).trustedForm.token,
    });
    return;
  },
  get: async (id) => {
    const response = await api.get(`web-api/web/program/${id}`);
    return response.data;
  },
  getPublic: async (id, token) => {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const response = await api.get(`web-api/web/program/${id}`);
    return response.data;
  },
  apply: async (id) => {
    await api.post(`web-api/web/program/${id}/apply`, {
      trustedToken: (window as any).trustedForm.token,
    });
    return;
  },
  visit: async (id) => {
    await api.get(`web-api/web/program/${id}/visit`);
    return;
  },
};

export default programApi;
