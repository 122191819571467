import programApi from "Program/api";

const programTypes = {
  new: {
    label: "new",
    action: programApi.getNewList,
  },
  visited: {
    label: "visited",
    action: programApi.getVisitedList,
  },
  applied: {
    label: "applied",
    action: programApi.getSubmittedList,
  },
};

export default programTypes;
