import cx from "Core/utils/cx";
import { useState } from "react";
import { useMounted } from "Core/hooks";
import Modal from "Core/components/Modal";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";

type Props = {
  ok?: string;
  info?: string;
  title?: string;
  cancel?: string;
  onOk: () => void;
  onCancel: () => void;
};

const BaseModal: React.FC<Props> = ({ title, info, ok, cancel, onOk, onCancel }) => {
  const [visible, setVisible] = useState(false);

  const handleOnCancel = () => {
    setVisible(false);
    setTimeout(onCancel, 300);
  };

  const handleOnOk = () => {
    setVisible(false);
    setTimeout(onOk, 300);
  };

  useMounted(() => {
    setVisible(true);
  });

  return (
    <Modal
      closable={false}
      visible={visible}
      onClose={handleOnCancel}
      className={styles.innerContent}
      modalClassName={cx(styles.container, styles.content)}
    >
      {title && <h3>{title}</h3>}
      {info && <p>{info}</p>}
      {ok && (
        <Button type="primary" onClick={handleOnOk}>
          {ok}
        </Button>
      )}
      {cancel && (
        <Button type="outline" onClick={handleOnCancel}>
          {cancel}
        </Button>
      )}
    </Modal>
  );
};

export default BaseModal;
