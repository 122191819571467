type Props = (value: any, key: string) => any;

const get: Props = (value, key) => {
  let _value = value;
  const keys = key.split(".");
  keys.forEach((x) => {
    if (_value && _value[x]) {
      _value = _value[x];
    } else {
      _value = null;
    }
  });
  return _value;
};

export default get;
