export const validatePasswordMatch = (key: string) => (x: string, values: any) => {
  return !x ? "Password is required" : values[key] !== x ? "Passwords do not match" : "";
};

export const validateUsername = (label: string) => (x: any) => {
  const re = /^[a-zA-Z0-9\-_.]+$/;
  return !x?.trim() ? `${label} is required` : !re.test(x) ? `Invalid username` : "";
};

export const validateRequired = (label: string) => (x: any) => {
  return !x?.trim() ? `${label} is required` : "";
};

export const validateEmail = (label: string) => (x: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !x ? `${label} is required` : !re.test(x) ? "Invalid e-mail address" : "";
};

export const validatePhone = () => (x: any) => {
  return x && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(x) ? "Invalid phone number" : "";
};
