import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import Button from "Core/components/Button";
import objectUtils from "Core/utils/object";
import menuItems from "Core/constants/menuItems";
import useTranslate from "Core/context/translate";
import cx from "Core/utils/cx";

type Props = {
  active: keyof typeof menuItems;
};

const NavigationMenu: React.FC<Props> = ({ active }) => {
  const { t } = useTranslate();
  const isActive = (e: string) => active === e;

  return (
    <div className={styles.container}>
      {objectUtils.asArray(menuItems).map((x) => (
        <Link key={x.key} to={`/${x.key}`}>
          <Button
            type={isActive(x.key) ? "primary" : "text"}
            className={cx([styles.active, isActive(x.key)])}
            icon={isActive(x.key) ? x.value.iconFilled : x.value.icon}
          >
            {t(`footer.${x.value.label}`)}
          </Button>
        </Link>
      ))}
    </div>
  );
};

export default NavigationMenu;
