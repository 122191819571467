import { Route, Routes } from "react-router-dom";
import NotificationProvider from "./context/provider";
import NotificationListPage from "./pages/NotificationListPage";

const Notification: React.FC = () => {
  return (
    <NotificationProvider>
      <Routes>
        <Route element={<NotificationListPage />} index />
      </Routes>
    </NotificationProvider>
  );
};

export default Notification;
