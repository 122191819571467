import { createContext } from "react";
import { Profile } from "Profile/types/Profile";

export type ProfileContextProps = {
  data?: Profile[];
  loading: boolean;
  getList: () => void;
};

const ProfileContext = createContext({} as ProfileContextProps);

export default ProfileContext;
