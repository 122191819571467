import api from "Core/api";
import { ResourceBookmark } from "Resource/types/Bookmark";

type Props = () => Promise<ResourceBookmark[]>;

const getBookmarks: Props = async () => {
  const response = await api.post("resource-api/web/bookmark/list", { limit: 0, offset: 0 });
  return response.data;
};

export default getBookmarks;
