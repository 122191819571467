import { Route, Routes } from "react-router-dom";
import ResourceProvider from "./context/provider";
import ResourceFilterPage from "./pages/ResourceFilterPage";
import ResourceResultPage from "./pages/ResourceResultPage";
import ResourceBookmarksPage from "./pages/ResourceBookmarksPage";

const Resource: React.FC = () => {
  return (
    <ResourceProvider>
      <Routes>
        <Route element={<ResourceFilterPage />} index />
        <Route path="result" element={<ResourceResultPage />} />
        <Route path="bookmarks" element={<ResourceBookmarksPage />} />
      </Routes>
    </ResourceProvider>
  );
};

export default Resource;
