import Base from "./Base";
import ReactDOM from "react-dom";

type Payload = {
  ok?: string;
  info?: string;
  title?: string;
  cancel?: string;
};

type Props = (payload: Payload) => Promise<void>;

const OpenModal: Props = (payload) => {
  return new Promise<void>((resolve, reject) => {
    const onOk = () => {
      ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer")!);
      resolve();
    };

    const onCancel = () => {
      ReactDOM.unmountComponentAtNode(document.getElementById("modalContainer")!);
      reject();
    };

    ReactDOM.render(<Base {...payload} onOk={onOk} onCancel={onCancel} />, document.getElementById("modalContainer"));
  });
};

export default OpenModal;
