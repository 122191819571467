import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { Option } from "Core/types/Option";

type Props = {
  value: string;
  options: Option[];
  onChange: (e: string) => void;
};

const RadioGroup: React.FC<Props> = ({ value, onChange, options }) => {
  const handleOnClick = (e: string) => () => {
    onChange(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {options.map((x) => (
          <div
            key={x.value}
            onClick={handleOnClick(x.value)}
            className={cx(styles.item, [styles.active, x.value === value])}
          >
            {x.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
