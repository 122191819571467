import { Button } from "Core/components";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import useTranslate from "Core/context/translate";
import ResourceFilterType from "Resource/components/ResourceFilterType";
import ResourceFilterSelect from "Resource/components/ResourceFilterSelect";

const ResourceFilter: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate("/resource/result");
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>{t("resources.filter.title")}</h1>
        <h2>{t("resources.filter.description")}</h2>
        <div className={styles.form}>
          <ResourceFilterSelect />
          <ResourceFilterType />
          <Button onClick={handleOnClick}>{t("resources.filter.submit")}</Button>
        </div>
      </div>
    </div>
  );
};

export default ResourceFilter;
