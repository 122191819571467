import { Fragment } from "react";
import { NavigationMenu, Header } from "Core/components";
import ResourceFilter from "Resource/components/ResourceFilter";
import ResourceBookmarksButton from "Resource/components/ResourceBookmarksButton";

const ResourceFilterPage: React.FC = () => {
  return (
    <Fragment>
      <Header center={<NavigationMenu active="resource" />} right={<ResourceBookmarksButton />} />
      <ResourceFilter />
    </Fragment>
  );
};

export default ResourceFilterPage;
