import cx from "Core/utils/cx";
import { useState } from "react";
import Icon from "Core/components/Icon";
import faqs from "Public/constants/faqs";
import styles from "./styles.module.scss";

const Faq: React.FC = () => {
  const [active, setActive] = useState<number>();

  const isActive = (e: number) => active === e;

  const handleOnClick = (e: number) => () => {
    setActive(e);
  };

  return (
    <section id="FAQ" className={styles.container}>
      <div className={styles.content}>
        <h4>FAQ</h4>
        <p>
          Find out the answers to the most frequently asked questions so you can get started and use ReliefScout like a
          pro.
        </p>
        {faqs.map((x, i) => (
          <div key={i} className={cx(styles.card, [styles.active, isActive(i)])} onClick={handleOnClick(i)}>
            <label>{x.question}</label>
            <summary>{x.answer}</summary>
            <Icon name="chevronDown" />
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;
