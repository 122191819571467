import Root from "./Root";
import "Core/styles/main.scss";
import ReactDOM from "react-dom";
import AuthProvider from "Auth/context/provider";
import { BrowserRouter } from "react-router-dom";
import TranslateProvider from "Core/context/translate/provider";

ReactDOM.render(
  <BrowserRouter>
    <TranslateProvider>
      <AuthProvider>
        <Root />
      </AuthProvider>
    </TranslateProvider>
    <div id="modalContainer" />
  </BrowserRouter>,
  document.getElementById("root")
);
