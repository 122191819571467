import { useState } from "react";
import ProgramContext from "./context";
import { ProgramList } from "Program/types/Program";
import { useRequest, useUpdated } from "Core/hooks";
import programTypes from "Program/constants/programTypes";
import { ProgramListType } from "Program/types/ProgramList";

const ProgramProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<ProgramList>();
  const [type, setType] = useState<ProgramListType>("new");
  const { request, loading } = useRequest(programTypes[type].action, { showError: true });

  const getList = async () => {
    setData(undefined);
    const response = await request();
    setData(response);
    return;
  };

  useUpdated(() => {
    getList();
  }, type);

  return (
    <ProgramContext.Provider value={{ data, loading, getList, type, setType }}>{children}</ProgramContext.Provider>
  );
};

export default ProgramProvider;
