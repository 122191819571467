import { RefObject, useCallback, useEffect } from "react";

type Callback = () => void;
type UseOuterClick = (callback: Callback, ref: RefObject<HTMLElement>) => void;

const useOuterClick: UseOuterClick = (callback, ref) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(() => callback(), []);

  const listener = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      cb();
    }
  };

  const handler = useCallback(() => {
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handler, [ref, cb]);
};

export default useOuterClick;
