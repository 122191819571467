import useAuth from "Auth/context";
import profileApi from "Profile/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import { ImageFile } from "Core/types/Image";
import { getAvatar, getUserLabel } from "Auth/utils";
import { Button, LoadingOverlay, Upload } from "Core/components";

const UpdateAvatar: React.FC = () => {
  const auth = useAuth();
  const { request, loading } = useRequest(profileApi.updateAvatar, { showError: true });

  const handleOnChange = async (e: ImageFile) => {
    request(e).then(() => {
      auth.getData();
    });
  };

  return (
    <LoadingOverlay loading={loading}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src={getAvatar(auth.data?.avatar)} alt={getUserLabel(auth.data!)} />
          <Upload onChange={handleOnChange}>
            <Button icon="camera">Change Photo</Button>
          </Upload>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default UpdateAvatar;
