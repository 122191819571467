import { useState } from "react";
import { useRequest } from "Core/hooks";
import NotificationContext from "./context";
import notificationApi from "Notification/api";
import { NotificationList } from "Notification/types/Notification";

const NotificationProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<NotificationList>();
  const readReq = useRequest(notificationApi.markRead);
  const deleteReq = useRequest(notificationApi.delete);
  const { request, loading } = useRequest(notificationApi.getList);

  const getList = () => {
    request().then(setData);
  };

  const onRead = (id: string) => {
    readReq.request(id).then(getList);
  };

  const onDelete = (id: string) => {
    deleteReq.request(id).then(getList);
  };

  return (
    <NotificationContext.Provider value={{ data, loading, getList, onRead, onDelete }}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
