import cx from "Core/utils/cx";
import styles from "./styles.module.scss";
import { Modify } from "Core/types/Modify";
import { ChangeEvent, HTMLInputTypeAttribute, HTMLProps } from "react";

type CustomProps = {
  value: string;
  label?: string;
  error?: string;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  onChange?: (e: string) => void;
};

type Props = Modify<HTMLProps<HTMLInputElement>, CustomProps>;

const Input: React.FC<Props> = ({ type, label, placeholder, error, value, onChange, ...props }) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e.target.value);
  };

  return (
    <div className={cx(styles.container, [styles.error, !!error])}>
      <label>{label}</label>
      <input
        {...props}
        type={type}
        value={value}
        disabled={!onChange}
        onChange={handleOnChange}
        placeholder={placeholder}
        className={cx([styles.filled, !!value])}
      />
      {error && <span>{error}</span>}
    </div>
  );
};

export default Input;
