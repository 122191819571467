type Props = (file?: File) => Promise<string>;

const toString: Props = (file) => {
  return new Promise<string>((resolve) => {
    var reader = new FileReader();

    reader.onloadend = () => {
      resolve(reader.result?.toString() || "");
    };

    reader.readAsDataURL(file!);
  });
};

export default toString;
