import Header from "./Header";
import styles from "./styles.module.scss";
import Footer from "Public/components/Footer";

const PrivacyPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <h1>Privacy Policy</h1>
        <p>
          Modaramo Media, Inc. (<strong>“Company”</strong>, <strong>“we”</strong>, <strong>“our”</strong> or{" "}
          <strong>“us”</strong>) respects your privacy and is committed to protecting it through our compliance with
          this Privacy Policy (the <strong>“Policy”</strong>). This Policy describes the types of information we may
          collect from you or that you may provide when you: (i) visit the Company’s website located at{" "}
          <a href="https://www.reliefscout.com " rel="noreferrer">
            https://www.reliefscout.com 
          </a>{" "}
          (our <strong>“Website”</strong>), (ii) download, install or use the ReliefScout mobile application (our{" "}
          <strong>“App”</strong>), and/or (iii) visit any of our social media accounts or webpages (collectively, the{" "}
          <strong>“Social Media Sites”</strong>); and our practices for collecting, processing, using, maintaining,
          protecting, and disclosing that information.
        </p>
        <p>
          As used in this Policy, the word <strong>“Sites”</strong> shall include the Company’s Website, App, and Social
          Media Sites. By using the Company’s Sites, you consent to the collection, processing, use, retention,
          protection and disclosure of your information by Company as described in this Policy. You shall also be
          subject to any additional terms posted with respect to individual sections of the Company’s Sites, which also
          govern your use of the Company’s Sites. These additional terms include without limitation the Terms of Use
          located at:
          <a href=" https://www.reliefscout.com/tos" rel="noreferrer">
            https://www.reliefscout.com/tos
          </a>
           (the <strong>“Terms of Use”</strong>) for the Company’s Sites. In addition, you agree to be bound by the
          terms and conditions, privacy policy and/or other policies (the <strong>“Social Media Platform Rules”</strong>
          ) of any of the social media websites, platforms and applications on which the Company’s Social Media Sites
          reside. In addition, by downloading, installing and/or using the App, you agree to be bound by our App End
          User License Agreement, located at 
          <a href="https://www.reliefscout.com/eula" rel="noreferrer">
            https://www.reliefscout.com/eula
          </a>
           (the <strong>“EULA”</strong>).
        </p>
        <p>This Policy applies to information we collect:</p>
        <ul>
          <li>on the Sites, including through the App; and</li>
          <li>in email, text, and other electronic messages between you and the Company on the Sites.</li>
        </ul>
        <p>This Policy does not apply to information collected:</p>
        <ul>
          <li>
            Through any other means, including on any other website operated by Company or any third party or through
            other forms of communications such as telephone; or
          </li>
          <li>
            By any third party, including through any application or content (including advertising) that may link to or
            be accessible from or on the Sites, including the Programs (as defined in the Terms of Use).
          </li>
        </ul>
        <h3>Your Acceptance of this Policy</h3>
        <p>
          Please read this Policy carefully to understand our policies and practices regarding your information and how
          we will treat it. By accessing or using any of the Sites, including by downloading, installing or using the
          App, you agree to this Policy. If you do not agree to this Policy, you are directed to discontinue using and
          accessing the Company’s Sites and you should not download, install or use the App. The Company reserves the
          right to change or update this Policy at any time and without prior notice to you. Your continued access or
          use of the Sites after such changes or updates indicates your acceptance of the Policy as changed or updated.
          It is your responsibility to review this Policy regularly for any changes or updates. This Policy was last
          updated on October 26, 2023.
        </p>
        <h3>Information We Collect About You and How We Collect It</h3>
        <p>
          When you use our Sites, including when you download, access or use the App, the Company and/or third party
          service providers collect certain information about you (the <strong>“Sites Information”</strong>). Among the
          types of information from and about users of our Sites that we collect are:
        </p>
        <ul>
          <li>
            Personal Information. We receive or collect information that identifies you personally if you choose to
            provide such personal information to us via email or other means. When you sign up to become a user or
            contact us, you will be required to provide us with personal information about yourself (collectively, the{" "}
            <strong>“Personal Information”</strong>). Such Personal Information may include your name, address and
            e-mail address.
          </li>
          <li>
            Geolocation Information. Certain features and functionalities of the Sites are based on your location. In
            order to provide these features and functionalities, we may, with your consent, automatically collect
            geolocation information from your device(s) or wireless carrier and/or certain third-party service providers
            (collectively, <strong>“Geolocation Information”</strong>). For the App, collection of Geolocation
            Information occurs only when the App is running on your device.
          </li>
          <li>
            Third-Party Analytics. We and the third-party technology providers, ad exchanges, ad networks, advertisers,
            agencies, and ad servers with which we work use third-party analytics services (e.g., Google Analytics) to
            evaluate your use of the Sites, compile reports on activity, collect demographic data, analyze performance
            metrics, and collect and evaluate other information relating to the Sites and mobile and Internet usage
            (collectively, <strong>“Third-Party Analytics”</strong>). These third parties use cookies and other
            technologies to help analyze and provide us the data. You consent to the processing of data about you by
            these analytics providers in the manner and for the purposes set out in this Policy. Please be advised that
            if you opt out of any such service, you may not be able to use the full functionality of the Sites.
          </li>
          <li>
            Other Information. In addition to Personal Information, Geolocation Information, and Third-Party Analytics,
            we may automatically collect or receive additional information regarding you and your use of the Sites, your
            interactions with us and our advertising; and information regarding your computer and mobile devices used to
            access the Sites (collectively, the <strong>“Other Information”</strong>). Such Other Information may
            include:
            <ol>
              <li>
                From You. Information that you may post, submit, transmit, upload or otherwise provide (hereinafter,{" "}
                <strong>“Post”</strong>), including without limitation photographs, videos, posts, comments and reviews,
                to be published or displayed on the Sites, or transmitted to other users of the Sites or third parties.
                Additional information from you may include information you provide when you report a problem with our
                Sites, and details of transactions you carry out through our Sites.
              </li>
              <li>
                From and About Your Devices. The Sites, including the App, may access metadata and other information
                associated with other files stored on your devices. This may include, for example, photographs, audio
                and video clips, personal contacts, and address book information. We may also collect information
                regarding your mobile device, including: (a) the type of mobile device; (b) advertising Identifier
                (“IDFA” or “AdID”); (c) operating system and version (e.g., iOS, Android or Windows); (d) carrier; and
                (e) network type (WiFi, 3G, 4G, 5G, LTE, etc.).
              </li>
              <li>
                From Your Activity. We may collect or receive information regarding: (a) IP address, which may consist
                of a static or dynamic IP address and will sometimes point to a specific identifiable computer or mobile
                device; (b) browser type and language; (c) referring and exit pages and URLs; (d) date and time; and (e)
                details regarding your activity on the Sites, such as searches and other performance and usage data.
              </li>
              <li>
                From User Attribution Devices. User Attribution Devices help facilitate and enhance the Sites’
                communications and interactions with you. The Company may share use User Attribution Devices to inform,
                optimize and serve information and content to you. <strong>“User Attribution Devices”</strong> include:
                <ul>
                  <li>
                    <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on your smartphone or
                    the hard drive of your computer. Unless you have adjusted your browser setting so that it will
                    refuse cookies, our systems will issue cookies when you direct you visit our Sites, including our
                    App. Cookies may be used to personalize your experience on the Sites, among other reasons.
                  </li>
                  <li>
                    <strong>Flash Cookies.</strong> Certain features of our Sites may use local stored objects (or Flash
                    cookies) to collect and store information about your preferences and navigation to, from, and on our
                    Sites. Flash cookies are not managed by the same browser settings as are used for browser cookies.
                  </li>
                  <li>
                    <strong>Web Beacons.</strong> Our Sites may contain small electronic files known as web beacons
                    (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example, to
                    count users who have visited certain webpages and for other related statistics (for example,
                    recording the popularity of certain content on the Sites and verifying system and server integrity).
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ul>
        <h3>Third-Party Information Collection</h3>
        <p>
          When you use the Sites, including the App, certain third parties may collect information about you or your
          devices, including by using their own User Attribution Devices. These third parties may include without
          limitation advertisers, ad networks, and ad servers, analytics companies, your mobile device manufacturer and
          your mobile service provider. The information they collect may be associated with your Personal Information or
          they may collect information, including personal information, about your online activities over time and
          across different websites, apps, and other online services websites. They may use this information to provide
          you with interest-based (behavioral) advertising or other targeted content. We do not control these third
          parties' tracking technologies or how they may be used. If you have any questions about an advertisement or
          other targeted content, you should contact the responsible provider directly.
        </p>
        <p>
          If you wish to opt out of third-party interest-based advertising, please visit 
          <a href="http://www.networkadvertising.org" rel="noreferrer">
            http://www.networkadvertising.org
          </a>
           and 
          <a href="http://www.aboutads.info/choices" rel="noreferrer">
            http://www.aboutads.info/choices
          </a>
           for details on how to potentially do so.
        </p>
        <h3>How and Why We Use Your Information</h3>
        <p>
          We may use any information about our users, including without limitation any Sites Information, in aggregated,
          anonymized and/or non-personally identifiable form, without restriction (except to the extent prohibited by
          applicable law). We may use information that we collect about you or that you provide to us as described in
          this Policy, including without limitation any Sites Information:
        </p>
        <ul>
          <li>To personalize your experience on the Sites, including on the App.</li>
          <li>To improve our customer service.</li>
          <li>To present our Sites and their contents to you.</li>
          <li>To provide you with information or services that you request, including from the App.</li>
          <li>To fulfill any other purpose for which you provide it.</li>
          <li>
            To carry out our obligations and enforce our rights arising from any contracts entered into between you and
            us, including for billing and collection.
          </li>
          <li>To notify you about changes to our Sites or any services we offer or provide though it.</li>
          <li>To administer a contest, sweepstakes, other promotion, survey or other feature of the Sites.</li>
          <li>To allow you to participate in interactive features on our Sites.</li>
          <li>In any other way we may describe when you provide the information.</li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          You expressly consent to the Company using your Sites Information, including without limitation your Personal
          Information, to contact you regarding your activities on the Sites. We may also use your Sites Information to
          contact you about Company news and updates and about our own and third-parties’ services that may be of
          interest to you.
        </p>
        <h3>Disclosure of Your Information</h3>
        <p>
          We may disclose any information about our users, including without limitation any Sites Information, in
          aggregated, anonymized and/or non-personally identifiable form, without restriction (except to the extent
          prohibited by applicable law). We may disclose information that we collect about you or that you provide to us
          as described in this Policy, including without limitation any Sites Information:
        </p>
        <ul>
          <li>
            To contractors, service providers, and other third parties we use to support our business (including but not
            limited to online service vendors) and who are bound by contractual obligations to keep the information that
            we disclose about you confidential and use it only for the purposes for which we disclose it to them. These
            contractors, service providers, and other third parties will have access to your Sites Information only as
            necessary to perform their functions and to the extent permitted by law. We also impose contractual
            obligations on contractors, service providers, and other third parties relating to ensure they can only use
            your Sites Information to provide services to us and to you.
          </li>
          <li>
            To a subsidiary, affiliate, buyer or to any other successor in the event of a merger, divestiture,
            restructuring, consolidation, reorganization, dissolution, or other sale or transfer of some or all of
            Company’s assets, whether as a going concern or as part of bankruptcy, insolvency, liquidation, or similar
            proceeding, in which information held by the Company about you is among the assets transferred.
          </li>
          <li>
            To third parties to market their products or services to you if you have not opted out of these disclosures.
            We contractually require these third parties to keep Personal Information confidential and use it only for
            the purposes for which we disclose it to them.
          </li>
          <li>
            In an ongoing effort to better understand our users, the Sites (including the App), and our products and
            services, we may analyze certain Sites Information in anonymized and aggregate form to operate, maintain,
            manage, and improve the Sites and/or such products and services. This aggregate information does not
            identify you personally. We may share and/or license this aggregate data to our affiliates, agents, business
            and promotional partners, and other third parties. We may also disclose aggregated user statistics to
            describe the App and these products and services to current and prospective business partners and investors
            and to other third parties for other lawful purposes.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>For any other purpose disclosed by us when you provide the Sites Information.</li>
          <li>Otherwise with your consent.</li>
        </ul>
        <p>We may also disclose your information, including without limitation any Sites Information:</p>
        <ul>
          <li>
            To comply with any court order, law, or legal process, including responding to any government or regulatory
            request.
          </li>
          <li>
            To enforce or apply our Terms of Use, EULA and other agreements, including for billing and collection
            purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
            Company, our customers, or others. This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk reduction.
          </li>
        </ul>
        <h3>Choices About How We Use and Disclose Your Information</h3>
        <p>
          We strive to provide you with choices regarding the Sites Information you provide to us. We have created
          mechanisms to provide you with the following control over your Sites Information:
        </p>
        <ul>
          <li>
            Tracking Technologies and Advertising. You may opt-out of collection and use of information by certain User
            Attribution Devices in connection with your use of the Sites by: (i) visiting{" "}
            <a href="http://optout.aboutads.info/" rel="noreferrer">
              http://optout.aboutads.info/
            </a>
            ; and/or (ii) setting your browser to disable or refuse certain types of User Attribution Devices, such as
            cookies. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on
            Adobe’s website located at:{" "}
            <a
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              rel="noreferrer"
            >
              http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html
            </a>
            . If you disable or refuse cookies, please note that you can still use the Sites, including our App,
            although some parts of the Sites may then be inaccessible or not function properly.
          </li>
          <li>
            Promotional Offers from the Company. If you do not wish to have your email address used by the Company to
            promote our own or third parties’ products or services, you can opt-out by sending us an email stating your
            request to{" "}
            <a href="mailto:optout@reliefscout.com" rel="noreferrer">
              optout@reliefscout.com
            </a>
            . If we have sent you a promotional email, you may send us a return email asking to be omitted from future
            email distributions. This opt out does not apply to information provided to the Company as a result of a
            purchase, request for services or other related transactions.
          </li>
          <li>
            Geolocation Information. You can choose whether or not to allow the Sites to collect and use real-time
            information about your device's location. You may decline to allow us to collect Geolocation Information, in
            which case we will not be able to provide certain features or functionalities to you.
          </li>
        </ul>
        <p>
          In any such case of your withdrawal of consent, you acknowledge that there may be a delay before the Company
          fully implements your request and you may therefore still be contacted by the Company for a period of time
          thereafter. Notwithstanding your withdrawal of consent, the Company may subsequently contact you for other
          purposes that are unrelated to marketing and/or selling, including without limitation legal or regulatory
          purposes. Please be aware that applicable laws, statues, rules and/or regulations may require or permit the
          collection, processing, retention, use and disclosure of your Sites Information without your consent.
          Notwithstanding the foregoing, pursuant to applicable laws, statues, rules and/or regulations or other
          reasons, there may be circumstances in which you may not withdraw your consent to the collection, processing,
          use, retention and disclosure of your Sites Information.
        </p>
        <p>
          We do not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser
          “Do Not Track” settings and/or signals.
        </p>
        <h3>The Company’s Social Media Sites</h3>
        <p>
          The Company maintains accounts on third party social media websites and applications. Your use of the
          Company’s Social Media Sites shall be subject to all of the following: this Policy; the Terms of Use; the
          EULA; and the terms of use, privacy policy, and all other applicable terms and conditions for each social
          media website, platform and application on which the Company’s Social Media Sites reside, as in effect at such
          time. In addition, when you register to use social media websites and platforms in general, you are generally
          required to furnish profile and other information to such websites and platforms in accordance with their own
          internal policies. Therefore, by using the Company’s Social Media Sites, you authorize the Company to collect
          and retain information about you, including your profile and other information you disclosed to such social
          media websites, platforms and applications and other information arising from your access and use of the
          Company’s Social Media Sites. You expressly consent to the Company’s collection, processing, retention, use
          and disclosure of such information in accordance with this Policy and the Terms of Use.
        </p>
        <h3>App Stores; External Websites</h3>
        <p>
          Your app store (e.g., iTunes or Google Play) may collect certain information in connection with your use of
          the App, such as Personal Information, Geolocation Information, and other usage-based data. We have no control
          over the collection of such information by a third-party app store, and any such collection or use will be
          subject to that third party’s applicable privacy policies. The Sites may contain links to third-party
          websites. We have no control over the privacy practices or the content of these websites. As such, we are not
          responsible for the content or the privacy policies of those third-party websites. You should check the
          applicable third-party privacy policy and terms of use when visiting any other websites.
        </p>
        <h3>Children</h3>
        <p>
          The Sites are not intended for children under the age of thirteen (13). No one under the age of thirteen may
          provide any personal information to or on the Sites. The Company is committed to the safety of children and to
          protecting the online privacy of children. The Company does not request or knowingly collect any personal
          information from children under the age of thirteen. If the Company becomes aware that a child under the age
          of thirteen has provided personal information to the Sites, the Company shall take reasonable measures to
          delete all such information from the Company’s records. The Company relies on the parents and guardians of
          children to monitor their children’s access and use of the Internet, including the Company’s Sites. If you
          believe we might have any information from or about a child under the age of thirteen, please contact us at{" "}
          <a href="mailto:info@reliefscout.com" rel="noreferrer">
            info@reliefscout.com
          </a>
          .
        </p>
        <h3>Residents of California, Colorado, Connecticut and Virginia</h3>
        <p>
          You have certain rights relating to your personal information subject to local data protection laws. In
          certain circumstances, you have the following rights:
        </p>
        <table>
          <tbody>
            <tr>
              <td>The Right to Know/Access</td>
              <td>
                <p>
                  The right to request or access any or all of the following information relating to your personal
                  information we have collected and disclosed in the last 12 months, upon our verification of your
                  identity:
                </p>
                <ul>
                  <li>The categories of personal information we have collected about you;</li>
                  <li>The categories of sources of the personal information we have collected about you;</li>
                  <li>Our business purpose for collecting that personal information;</li>
                  <li>The categories of third parties with whom we disclose that personal information; and </li>
                  <li>The specific pieces of personal information we have collected about you.</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>The Right to Request Deletion</td>
              <td>
                The right to request the deletion of personal information we have collected from you, including personal
                data collected through third parties.
              </td>
            </tr>
            <tr>
              <td>The Right to Correct</td>
              <td>The right to correct inaccurate personal information that we maintain about you.</td>
            </tr>
            <tr>
              <td>The Right to Opt Out of Targeted Advertising or the Sale or Sharing of Personal Information</td>
              <td>
                The right to direct us not to sell, use or share personal information for targeted advertising or
                certain types of profiling about you to third parties now or in the future.
              </td>
            </tr>
            <tr>
              <td>The Right to Download and Remove</td>
              <td>
                The right to download and remove personal data from a platform, if applicable, in a format that allows
                the transfer to another platform
              </td>
            </tr>
            <tr>
              <td>The Right to Non-Discrimination</td>
              <td>The right not to receive discriminatory treatment for exercising these rights.</td>
            </tr>
            <tr>
              <td>The Right to Appeal</td>
              <td>You may have the right to appeal a decision we may make related to your request.</td>
            </tr>
            <tr>
              <td>“Shine the Light”</td>
              <td>
                California Civil Code Section 1798.83 permits you to request information regarding the disclosure of
                your personal information to a third party for the third party's direct marketing purposes within the
                prior calendar year.
              </td>
            </tr>
          </tbody>
        </table>
        <p>Please note the above rights are subject to certain exceptions.</p>
        <h3>How To Exercise Your Rights</h3>
        <p>To exercise any rights you may have as described above, please submit a request to us by:</p>
        <ul>
          <li>Submitting a data access request Here </li>
          <li>
            Sending an email to{" "}
            <a href="mailto:info@reliefscout.com" rel="noreferrer">
              info@reliefscout.com
            </a>
          </li>
        </ul>
        <p>
          Only you, or a person or entity that you authorize to act on your behalf, may make a verifiable request
          related to your personal information. We may not be able to fulfill your request or provide you with personal
          information if we cannot verify your identity or authority to make the request and confirm the personal
          information relates to you. We will need to verify your identity before processing your request. In order to
          verify your identity, we will generally require the matching of sufficient information you provide us to the
          information we maintain about you in our systems. If you are using an authorized agent, please note that you
          will be required to verify your identity and provide written confirmation that you have authorized the agent
          to make a request on your behalf. For requests to access or delete, we may require you to verify your identity
          directly with us, and directly confirm with us that you provided the authorized agent permission to submit the
          request. We will respond to most requests within forty-five (45) days, or otherwise request an additional
          forty-five (45) day period within which to respond.‍
        </p>
        <h3>Risks</h3>
        <p>
          You acknowledge that you are aware of security and privacy limitations including but not limited to: (1) the
          global accessibility of the Company’s Sites on the Internet; (2) the technological limitations of security,
          privacy, and authentication measures and features on Internet sites and specifically on the Company’s Sites;
          (3) the risk that data or information is transmitted to or from the Company’s Sites may be subject to
          eavesdropping, sniffing, spoofing, forgery, spamming, “impostering”, tampering, breaking passwords,
          harassment, fraud, electronic trespassing, hacking, denial of service attacks, nuking, system contamination
          (including computer viruses, Trojan horses, worms, defects, date bombs, time bombs, malware, ransomware, bugs,
          executables or other items of a destructive nature or any other malicious computer codes, scripts,
          applications or programs) causing unauthorized, damaging, or harmful access to and/or retrieval of information
          and data on your computer or network; (4) the risk that data or information on any of the Company’s Sites may
          be subject to other security or privacy hazards, may not reach its destination, or may reach an erroneous
          address or recipient; (5) unauthorized access by third parties; and (6) the content or the privacy policies of
          other websites, social media websites, platforms, and applications, including without limitation those to
          which the Company may link or be linked.
        </p>
        <h3>Data Security</h3>
        <p>
          The Company considers protecting the security of your Personal Information important. We have implemented
          commercially reasonable measures to protect your Personal Information from accidental loss, from misuse and
          from unauthorized access, alteration, disclosure or destruction. Please understand, however, that no security
          system is impenetrable. We cannot guarantee the security of our databases or the databases of the third
          parties with which we may share Personal Information, nor can we guarantee that the Personal Information you
          supply will not be intercepted while being transmitted over the Internet. Any transmission of information,
          including Personal Information, is at your own risk.
        </p>
        <p>
          The safety and security of your Personal information also depends on you. Where we have given you (or where
          you have chosen) a password to access certain parts of our Sites, you are responsible for keeping this
          password confidential. We ask you not to share your password with anyone. We urge you to be careful about
          giving out information in public areas of the Sites, which may be viewed by others. You must take precautions
          to protect the security of all Personal Information that you Post or otherwise transmit to or through the
          Sites, including over any home networks, routers, private wireless (WiFi) networks, public WiFi networks, and
          all other communication technologies. We are not responsible for circumvention of any privacy settings or
          security measures contained on the Sites, for the unauthorized acts of others, or for acts or omissions beyond
          our reasonable control.
        </p>
        <h3>Law Enforcement</h3>
        <p>
          The Company fully cooperates with law enforcement personnel and agencies in identifying those who use the
          Company’s Sites and/or services for illegal activities. The Company reserves the right to release information
          to law enforcement agencies about any user of the Sites, including without limitation any Sites Information
          and/or Personal Information, whenever required by law, court order, other legal process, government agency or
          authority, or regulatory agency or authority or whenever the Company, in its sole and absolute discretion,
          believes: (i) a user is engaging in any activities in violation of this Policy, the Terms of Use, the EULA,
          and/or applicable law; (ii) disclosure is necessary to prevent physical harm or financial loss; or (iii) is
          necessary in connection with an investigation of suspected and/or actual illegal activity.
        </p>
        <h3>How to Contact Us</h3>
        <p>To ask questions or comment about this Policy and our privacy practices, contact us at:</p>
        <p>
          Modaramo Media, Inc.
          <br />
          2 Seaview Blvd, Suite 100
          <br />
          Port Washington, NY 11050
        </p>
        <p>Alternatively, you may email us at: info@reliefscout.com.</p>
      </div>
      <Footer />
    </div>
  );
};
export default PrivacyPage;
