import { IconName } from "Core/types/Icon";

type Props = Record<string, { label: string; icon: IconName; iconFilled: IconName }>;

const menuItems: Props = {
  program: {
    label: "program",
    icon: "program",
    iconFilled: "programFilled",
  },
  resource: {
    label: "resources",
    icon: "resource",
    iconFilled: "resourceFilled",
  },
  notification: {
    label: "notifications",
    icon: "notification",
    iconFilled: "notificationFilled",
  },
};

export default menuItems;
