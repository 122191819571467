import { Option } from "Core/types/Option";

const radioItems: Option[] = [
  {
    label: "Government",
    value: "government",
  },
  {
    label: "Private",
    value: "private",
  },
];

export default radioItems;
