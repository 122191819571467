import fileUtils from "Core/utils/file";
import styles from "./styles.module.scss";
import { ImageFile } from "Core/types/Image";
import Cropper from "Core/components/Cropper";
import { ChangeEvent, useRef, useState } from "react";

type Props = {
  ratio?: number;
  onChange: (file: ImageFile) => void;
};

const Upload: React.FC<Props> = ({ ratio, children, onChange }) => {
  const [file, setFile] = useState<File>();
  const ref = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState<string>();

  const handleOnOpen = () => {
    ref.current?.click();
  };

  const handleOnClose = () => {
    ref.current!.value = "";
    setFile(undefined);
    setValue(undefined);
  };

  const handleOnChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileString = await fileUtils.toString(e.target.files![0]);
    setFile(e.target.files![0]);
    setValue(fileString);
  };

  const handleOnFinish = (e: string) => {
    onChange({ fileName: file!.name, type: "PNG", data: e.replace("data:image/png;base64,", "") });
    handleOnClose();
  };

  return (
    <>
      <span className={styles.container} onClick={handleOnOpen}>
        {children}
      </span>
      <input ref={ref} type="file" accept="image/*" onChange={handleOnChange} className={styles.input} />
      {value && <Cropper ratio={ratio} image={value} onCancel={handleOnClose} onFinish={handleOnFinish} />}
    </>
  );
};

export default Upload;
