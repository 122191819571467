import { useState } from "react";
import useMounted from "./useMounted";
import OpenModal from "Core/components/OpenModal";
import useTranslate from "Core/context/translate";

type Action<U> = () => Promise<U>;

type Options = {
  showError?: boolean;
};

const useGet = <U>(action: Action<U>, options?: Options) => {
  const { t } = useTranslate();
  const [data, setData] = useState<U>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateState = (x: boolean, y: string) => {
    setLoading(x);
    setError(y);
  };

  const request: Action<U> = async () => {
    try {
      updateState(true, "");
      const response = await action();
      updateState(false, "");
      setData(response);
      return response;
    } catch (error) {
      updateState(false, String(error));
      if (options?.showError) {
        OpenModal({ title: t("error.title"), info: String(error), ok: t("continue") });
      }
      throw error;
    }
  };

  useMounted(() => {
    request();
  });

  return {
    data,
    error,
    loading,
    request,
  };
};

export default useGet;
