import styles from "./styles.module.scss";
import Button from "Core/components/Button";

type Props = {
  edit: boolean;
  onDone: () => void;
  onSelect: () => void;
  onCancel: () => void;
  onRevert: () => void;
};

const Footer: React.FC<Props> = ({ edit, onCancel, onRevert, onDone, onSelect }) => {
  return (
    <div className={styles.container}>
      <Button type="text" onClick={onCancel}>
        Cancel
      </Button>
      <div className={styles.buttons}>
        {edit ? (
          <Button className={styles.done} onClick={onSelect}>
            Select
          </Button>
        ) : (
          <>
            <Button type="outline" className={styles.revert} onClick={onRevert}>
              Revert
            </Button>
            <Button className={styles.done} onClick={onDone}>
              Done
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default Footer;
