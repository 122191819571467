import { Fragment } from "react";
import { useMounted } from "Core/hooks";

const ProgramTrustedForm: React.FC = () => {
  useMounted(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.innerHTML = `(function () {
      var field = 'xxTrustedFormCertUrl';
      var provideReferrer = false;
      var invertFieldSensitivity = false;
      var tf = document.createElement('script');
      tf.type = 'text/javascript'; tf.async = true;
      tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
        '://api.trustedform.com/trustedform.js?provide_referrer=' +
        escape(provideReferrer) + '&field=' + escape(field) + '&l=' + new
          Date().getTime() + Math.random() + '&invert_field_sensitivity=' +
        invertFieldSensitivity;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(tf, s);
    }
    )();`;

    document.body.appendChild(script);
  });

  return <Fragment />;
};

export default ProgramTrustedForm;
