import styles from "./styles.module.scss";
import Button from "Core/components/Button";
import EmptyUrl from "Core/assets/empty.svg";

type Props = {
  info: string;
  title: string;
  button?: string;
  isEmpty: boolean;
  onClick?: () => void;
};

const EmptyOverlay: React.FC<Props> = ({ title, info, button, onClick, isEmpty, children }) => {
  return isEmpty ? (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={EmptyUrl} alt="" />
        <h1>{title}</h1>
        <p>{info}</p>
        {button && <Button onClick={onClick}>{button}</Button>}
      </div>
    </div>
  ) : (
    <>{children}</>
  );
};

export default EmptyOverlay;
