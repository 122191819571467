import cx from "Core/utils/cx";
import { MouseEvent } from "react";
import Icon from "Core/components/Icon";
import styles from "./styles.module.scss";
import { IconName } from "Core/types/Icon";

export type ButtonStyle =
  | "text"
  | "link"
  | "light"
  | "danger"
  | "primary"
  | "outline"
  | "warning"
  | "secondary"
  | "primaryOutline";

type Props = {
  icon?: IconName;
  loading?: boolean;
  type?: ButtonStyle;
  disabled?: boolean;
  className?: string;
  htmlType?: "button" | "submit" | "reset";
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const Button: React.FC<Props> = ({
  icon,
  loading,
  onClick,
  children,
  disabled,
  className,
  type = "primary",
  htmlType = "button",
}) => {
  return (
    <button
      type={htmlType}
      onClick={onClick}
      disabled={disabled || loading}
      className={cx(styles.container, styles[type], className)}
    >
      {loading && (
        <div className={styles.loading}>
          <Icon name="loading" />
        </div>
      )}
      {icon ? <Icon name={icon} className={cx(styles.icon)} /> : <></>}
      {children}
    </button>
  );
};

export default Button;
