import { useState } from "react";
import profileApi from "Profile/api";
import { useRequest } from "Core/hooks";
import styles from "./styles.module.scss";
import surveyUtils from "Core/utils/survey";
import { useNavigate } from "react-router-dom";
import Question from "Core/components/Question";
import { SurveyAnswer } from "Core/types/Survey";
import useTranslate from "Core/context/translate";
import { Button, OpenModal } from "Core/components";
import { Subprofile } from "Profile/types/Subprofile";

type Props = {
  value: Subprofile;
  profileId: string;
};

const SubprofileSurvey: React.FC<Props> = ({ value, profileId }) => {
  const { t } = useTranslate();
  const navigate = useNavigate();
  const saveReq = useRequest(profileApi.saveProfile, { showError: true });
  const [answers, setAnswers] = useState<SurveyAnswer[]>(value.answers);

  const handleOnChange = (e: SurveyAnswer) => {
    const index = answers.findIndex((x) => x.questionID === e.questionID);
    if (index > -1) {
      answers[index] = e;
      setAnswers([...answers]);
    } else {
      setAnswers((prev) => [...prev, e]);
    }
  };

  const handleOnClick = () => {
    saveReq.request(profileId, value._id, answers).then((response) => {
      if (response.qualifiedSubprofiles.length > 0) {
        navigate(`/profile/${profileId}/subprofile/123`);
      } else {
        const hasProgram = response.qualifiedPrograms.length > 0;
        OpenModal({
          cancel: t("profile.profiles.subprofile.save.goBack"),
          title: t(`profile.profiles.subprofile.save.${hasProgram ? "newPrograms" : "success"}.title`),
          info: t(`profile.profiles.subprofile.save.${hasProgram ? "newPrograms" : "success"}.description`),
        })
          .then(() => {
            navigate(hasProgram ? "/program" : "/resource");
          })
          .catch(() => {
            navigate("/resource");
          });
      }
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>{value.description}</p>
        {value.questions.map((x) => (
          <Question key={x._id} question={x} answer={surveyUtils.getAnswer(x, answers)} onChange={handleOnChange} />
        ))}
        <div className={styles.button}>
          <Button
            onClick={handleOnClick}
            loading={saveReq.loading}
            disabled={answers.length !== value.questions.length}
          >
            {t("profile.profiles.subprofile.submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubprofileSurvey;
