import styles from "./styles.module.scss";
import ProfileUrl from "Public/assets/profile.svg";

const Profile: React.FC = () => {
  return (
    <section id="HowTo" className={styles.container}>
      <div className={styles.content}>
        <div className={styles.left}>
          <div className={styles.overlay} />
          <img src={ProfileUrl} alt="Profiles" />
        </div>
        <div className={styles.right}>
          <h4>Take Advantage of Opportunities in Your Area</h4>
          <p>
            There are so many solutions available, but the question is which ones are right for you? We make finding the
            assistance you need simple. By analyzing the needs of each unique situation, we are able to match you to
            programs in your region that are tailored to your needs and goals.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Profile;
