import Footer from "./Footer";
import Toolbar from "./Toolbar";
import "cropperjs/dist/cropper.css";
import { useRef, useState } from "react";
import styles from "./styles.module.scss";
import Header from "Core/components/Header";
import Portal from "Core/components/Portal";
import Cropper, { ReactCropperElement } from "react-cropper";

type Props = {
  image: string;
  ratio?: number;
  onCancel: () => void;
  onFinish: (e: string) => void;
};

const UploadImage: React.FC<Props> = ({ ratio = 1, image, onCancel, onFinish }) => {
  const [edit, setEdit] = useState(true);
  const [value, setValue] = useState(image);
  const cropper = useRef<ReactCropperElement>(null);
  const [instance, setInstance] = useState<Cropper>();

  const onRevert = () => {
    setValue(image);
    setEdit(true);
  };

  const onSelect = () => {
    setValue(cropper.current?.cropper.getCroppedCanvas().toDataURL() || value);
    setEdit(false);
  };

  const onInitialized = (e: Cropper) => {
    setInstance(e);
  };

  const onDone = () => onFinish(value);

  return (
    <Portal container={document.querySelector("#root")!}>
      <div className={styles.container}>
        <Header center="Edit Photo" right={<></>} />
        <div className={styles.content}>
          <Cropper
            src={value}
            viewMode={1}
            ref={cropper}
            autoCropArea={1}
            responsive={true}
            background={false}
            aspectRatio={ratio}
            checkOrientation={false}
            className={styles.cropper}
            onInitialized={onInitialized}
          />
        </div>
        <Toolbar edit={edit} cropper={instance} />
        <Footer edit={edit} onCancel={onCancel} onSelect={onSelect} onDone={onDone} onRevert={onRevert} />
      </div>
    </Portal>
  );
};

export default UploadImage;
