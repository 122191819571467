import { useState } from "react";
import OpenModal from "Core/components/OpenModal";
import useTranslate from "Core/context/translate";

type Action<T extends Array<any>, U> = (...args: T) => Promise<U>;

type Options = {
  showError?: boolean;
};

const useRequest = <T extends Array<any>, U>(action: Action<T, U>, options?: Options) => {
  const { t } = useTranslate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const updateState = (x: boolean, y: string) => {
    setLoading(x);
    setError(y);
  };

  const request: Action<T, U> = async (...args: T) => {
    try {
      updateState(true, "");
      const response = await action(...args);
      updateState(false, "");
      return response;
    } catch (error) {
      updateState(false, String(error));
      if (options?.showError) {
        OpenModal({ title: t("error.title"), info: String(error), ok: t("continue") });
      }
      throw error;
    }
  };

  return {
    error,
    loading,
    request,
  };
};

export default useRequest;
