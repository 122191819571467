import styles from "./styles.module.scss";
import { Program } from "Program/types/Program";

type Props = {
  value?: Program;
};

const ProgramDetailIntro: React.FC<Props> = ({ value }) => {
  return (
    <div className={styles.container}>
      <img src={`data:image/png;base64, ${value?.image?.data}`} alt="" />
      <div className={styles.filter} />
      <div className={styles.content}>
        <h1>{value?.name}</h1>
        <h2>{value?.description}</h2>
      </div>
    </div>
  );
};
export default ProgramDetailIntro;
