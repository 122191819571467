import styles from "./styles.module.scss";
import Button from "Core/components/Button";

type Props = {
  edit: boolean;
  cropper?: Cropper;
};

const Toolbar: React.FC<Props> = ({ cropper, edit }) => {
  const onZoom = (e: number) => () => {
    cropper?.zoom(e);
  };

  const onRotate = (e: number) => () => {
    cropper?.rotate(e);
  };

  return (
    <div className={styles.container}>
      <Button className={styles.button} icon="zoomIn" onClick={onZoom(0.1)} />
      <Button className={styles.button} icon="zoomOut" onClick={onZoom(-0.1)} />
      {edit && (
        <>
          <Button className={styles.button} icon="rotateLeft" onClick={onRotate(90)} />
          <Button className={styles.button} icon="rotateRight" onClick={onRotate(-90)} />
        </>
      )}
    </div>
  );
};

export default Toolbar;
