import ProfileProvider from "./context/provider";
import { Route, Routes } from "react-router-dom";
import ProfileEditPage from "./pages/ProfileEditPage";
import ProfileHomePage from "./pages/ProfileHomePage";
import ProfileDetailPage from "./pages/ProfileDetailPage";
import ProfilePasswordPage from "./pages/ProfilePasswordPage";
import ProfileSubprofilePage from "./pages/ProfileSubprofileDetailPage";

const Profile: React.FC = () => {
  return (
    <ProfileProvider>
      <Routes>
        <Route element={<ProfileHomePage />} index />
        <Route path="edit" element={<ProfileEditPage />} />
        <Route path="password" element={<ProfilePasswordPage />} />
        <Route path=":id" element={<ProfileDetailPage />} />
        <Route path=":profileId/subprofile/:id" element={<ProfileSubprofilePage />} />
      </Routes>
    </ProfileProvider>
  );
};

export default Profile;
