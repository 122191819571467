import { useUpdated } from "Core/hooks";
import styles from "./styles.module.scss";
import { Button, Modal } from "Core/components";
import useTranslate from "Core/context/translate";
import useNotification from "Notification/context";
import { Notification } from "Notification/types/Notification";

type Props = {
  visible: boolean;
  value: Notification;
  onClose: () => void;
};

const NotificationDrawer: React.FC<Props> = ({ value, visible, onClose }) => {
  const { t } = useTranslate();
  const notification = useNotification();

  useUpdated(() => {
    if (visible && !value.read) {
      notification.onRead(value._id);
    }
  }, visible);

  return (
    <Modal visible={visible} onClose={onClose} closable={false} drawer>
      <div className={styles.container}>
        <Button type="light" icon="chevronLeft" onClick={onClose} className={styles.close} />
        {value.imageUrl && <img src={value.imageUrl} alt={value.title} />}
        <h3>{value.title}</h3>
        <p>{value.body}</p>
        {value.websiteUrl && (
          <a href={value.websiteUrl} target="_blank" rel="noreferrer">
            <Button type="primary">{t("notifications.detail.continue")}</Button>
          </a>
        )}
      </div>
    </Modal>
  );
};
export default NotificationDrawer;
