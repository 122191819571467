import styles from "./styles.module.scss";
import useResource from "Resource/context";
import { RadioGroup } from "Core/components";
import { radioItems } from "Resource/constants";

const ResourceSelect: React.FC = () => {
  const { filter, setFilter } = useResource();

  const handleOnChange = (e: string) => {
    setFilter({ ...filter, isPrivate: e === "private" });
  };

  return (
    <div className={styles.container}>
      <RadioGroup value={filter.isPrivate ? "private" : "government"} onChange={handleOnChange} options={radioItems} />
    </div>
  );
};

export default ResourceSelect;
