const faqs = [
  {
    question: "How Does ReliefScout Work?",
    answer:
      "ReliefScout works in 3 easy steps. Sign up, answer a few quick questions about your situation and instantly get matched to programs, resources and opportunities you may qualify for.",
  },
  {
    question: "How Do I Get Connected To Help?",
    answer:
      "By providing details about yourself, ReliefScout is able to show you solutions that are applicable to your needs and goals.",
  },
  {
    question: "Can I Apply To Programs?",
    answer:
      "Yes, you can easily apply to programs online and through the application. ReliefScout keeps track of programs you are interested in and have submitted.",
  },
  {
    question: "Are There New Resources Added?",
    answer:
      "ReliefScout is updated regularly. New solutions are consistently added to ensure you always have opportunities to explore.",
  },
  {
    question: "Should I Download The Mobile App?",
    answer:
      "Downloading the mobile app is a great way to be notified about the latest solutions in your area. It gives you quick access to help anytime and anywhere.",
  },
];

export default faqs;
