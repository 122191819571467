import { useRef } from "react";
import styles from "./styles.module.scss";
import Lottie, { AnimationItem } from "lottie-web";
import { useMounted, useUpdated } from "Core/hooks";
import animationData from "Core/assets/loading.json";

type Props = {
  loading?: boolean;
};

const LoadingOverlay: React.FC<Props> = ({ children, loading = true }) => {
  const ref = useRef<HTMLDivElement>(null);
  const lottieInstance = useRef<AnimationItem>();

  const load = () => {
    if (ref.current) {
      lottieInstance.current = Lottie.loadAnimation({
        loop: true,
        animationData,
        autoplay: true,
        container: ref.current,
      });
      lottieInstance.current.setSpeed(2);
    }
  };

  useMounted(() => {
    load();
  });

  useUpdated(() => {
    if (loading) load();
  }, loading);

  return (
    <>
      {loading && (
        <div className={styles.container}>
          <div className={styles.content} ref={ref} />
        </div>
      )}
      {children}
    </>
  );
};

export default LoadingOverlay;
