import { Link } from "react-router-dom";
import { useMounted } from "Core/hooks";
import styles from "./styles.module.scss";
import useProfile from "Profile/context/useContext";
import ProfileCard from "Profile/components/ProfileCard";

const SubprofileList: React.FC = () => {
  const profile = useProfile();

  useMounted(() => {
    profile.getList();
  });

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {profile.data?.map((x) => (
          <Link key={x._id} to={`/profile/${x._id}`}>
            <ProfileCard percent={x.percentage} title={x.name} info={x.description} />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SubprofileList;
