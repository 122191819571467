import { UseFormPayload } from "Core/hooks/useForm";
import { UpdatePasswordForm } from "Profile/types/PasswordForm";
import { validatePasswordMatch, validateRequired } from "Core/utils/validators";

type Props = () => UseFormPayload<UpdatePasswordForm>;

const createPasswordForm: Props = () => {
  return {
    currentPassword: {
      value: "",
      validate: validateRequired("Password"),
    },
    newPassword: {
      value: "",
      validate: validateRequired("New password"),
    },
    confirmPassword: {
      value: "",
      validate: validatePasswordMatch("newPassword"),
    },
  };
};

export default createPasswordForm;
