import api from "Core/api";
import { Resource } from "Resource/types/Resource";

type Props = (payload: Resource) => Promise<void>;

const addBookmark: Props = async (payload) => {
  await api.post("resource-api/web/bookmark/create", { data: payload });
  return;
};

export default addBookmark;
