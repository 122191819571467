import cx from "Core/utils/cx";
import { Icon } from "Core/components";
import styles from "./styles.module.scss";
import { Program } from "Program/types/Program";

type Props = {
  value: Program;
  active: number;
};
const ProgramSurveySteps: React.FC<Props> = ({ value, active }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {value.missingQuestions.map((x, i) => (
          <section key={x._id} className={cx([styles.active, i === active], [styles.inactive, i > active])}>
            <span>{i < active ? <Icon name="check" /> : i + 1}</span>
            <label>{x.text}</label>
          </section>
        ))}
      </div>
    </div>
  );
};

export default ProgramSurveySteps;
