import Header from "./Header";
import styles from "./styles.module.scss";
import Footer from "Public/components/Footer";

const TermsPage: React.FC = () => {
  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        <h1>Terms of Use</h1>
        <h3>Acceptance of the Terms of Use</h3>
        <p>
          These terms of use are entered into by and between you and Modaramo Media, Inc. (<strong>“Company”</strong>,{" "}
          <strong>“we”</strong>, <strong>“our”</strong> or <strong>“us”</strong>). The following terms and conditions,
          together with any documents they expressly incorporate by reference (collectively,{" "}
          <strong>“Terms of Use”</strong>), govern your access to and use of our website located at{" "}
          <a href="http://www.reliefscout.com" rel="noreferrer">
            http://www.reliefscout.com
          </a>
          (the <strong>“Website”</strong>), our ReliefScout mobile application (the <strong>“App”</strong>), and any of
          the Company’s social media accounts and webpages (collectively, the <strong>“Social Media Sites”</strong>),
          including any content, functionality, and services offered thereon. As used in this Terms, the word{" "}
          <strong>“Sites”</strong> shall include the Company’s Website, App and Social Media Sites.
        </p>
        <p>
          Please read these Terms of Use carefully before you start to use the Sites. By accessing and/or using the
          Sites, you accept and agree to be bound and abide by these Terms of Use and our Privacy Policy, located at{" "}
          <a href="https://www.reliefscout.com/privacy" rel="noreferrer">
            https://www.reliefscout.com/privacy
          </a>{" "}
          (the <strong>“Privacy Policy”</strong>), incorporated herein by reference. In addition, you agree to be bound
          by the terms and conditions, privacy policy and/or other terms and conditions (the{" "}
          <strong>“Social Media Platform Rules”</strong>) of any of the social media websites and applications on which
          the Company’s Social Media Sites reside. In no event shall the Company be responsible in any way for your
          violation of any of the Social Media Platform Rules. In addition, by downloading, installing and/or using the
          App, you agree to be bound by our App End User License Agreement, located at{" "}
          <a href="https://www.reliefscout.com/eula" rel="noreferrer">
            https://www.reliefscout.com/eula
          </a>{" "}
          (the <strong>“EULA”</strong>). It is your sole responsibility to read and comply with the Privacy Policy,
          Social Media Platform Rules and EULA. The Sites are offered and available to users who are eighteen (18) years
          of age or older. By using the Sites, you represent and warrant that you are of legal age to form a binding
          contract with the Company and meet all of the foregoing eligibility requirements. If you do not meet all of
          these requirements, you must not access or use the Sites.
        </p>
        <p>
          THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION (ON AN INDIVIDUAL BASIS ONLY; I.E., CASE CONSOLIDATIONS AND
          CLASS ACTIONS ARE NOT PERMITTED) IN ORDER TO RESOLVE DISPUTES. If you do not agree to these Terms of Use, our
          Privacy Policy or our EULA, you must not access or use the Sites. If you are dissatisfied with any of the
          Material or User Content (as such terms are defined in these Terms of Use) contained in the Company’s Sites,
          your sole and exclusive remedy is to discontinue accessing and using the Sites immediately.
        </p>
        <h3>Changes to the Terms of Use</h3>
        <p>
          We may revise and update these Terms of Use from time to time in our sole discretion and without prior notice
          to you. All changes are effective immediately when we post them, and apply to all access to and use of the
          Sites thereafter. However, any changes to the dispute resolution provisions set out in Governing Law and
          Jurisdiction will not apply to any disputes for which the parties have actual notice on or before the date the
          change is posted on the Site. Your continued use of the Sites following the posting of revised Terms of Use
          means that you accept and agree to the changes. You are expected to check this page regularly to time so you
          are aware of any changes, as they are binding on you. These Terms of Use were last updated on October 26,
          2023.{" "}
        </p>
        <h3>Changes to the Sites</h3>
        <p>
          We reserve the right to withdraw, amend or otherwise edit the Sites, and any service or Material (hereinafter
          defined) we provide on the Sites, in our sole discretion without notice. We will not be liable if for any
          reason all or any part of the Sites are unavailable at any time or for any period. You are responsible for
          both making all arrangements necessary for you to have access to the Sites and ensuring that all persons who
          access the Sites through your Internet connection are aware of these Terms of Use and comply with them.
        </p>
        <p>
          To access the Sites or some of the resources it offers, you may be asked to provide certain registration
          details or other information. It is a condition of your use of the Sites that all the information you provide
          is correct, current, and complete. You agree that all information you provide to register with the Sites or
          otherwise, including, but not limited to, through the use of any interactive features on the Sites, is
          governed by our Privacy Policy{" "}
          <a href="http://www.reliefscout.com/privacy" rel="noreferrer">
            http://www.reliefscout.com/privacy
          </a>
          , and you consent to all actions we take with respect to your information consistent with our Privacy Policy.
        </p>
        <h3>Intellectual Property Rights</h3>
        <p>
          The Sites and their entire contents, features, and functionality (including but not limited to all
          information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
          thereof) (collectively, the <strong>“Material”</strong>) are owned by the Company, its licensors, or other
          providers of such Material and are protected by United States and international copyright, trademark, patent,
          trade secret, and other intellectual property or proprietary rights laws, as well as other Laws (hereinafter
          defined). Nothing contained in the Sites shall be construed as granting any license or any other rights to any
          copyright, trademark, patent or other property of the Company or any other person or entity, whether by
          implication, laches, estoppel, explicit grant or otherwise. No right, title, or interest in or to the Sites or
          any Materials on the Sites is transferred to you.
        </p>
        <p>
          These Terms of Use permit you to use the Sites, including the App, for your personal, non-commercial use only.
          You may not use the Sites, including any service or products provided by the Sites, for any other purpose,
          including any commercial purpose, without the prior express written permission of an authorized representative
          of the Company. You must not reproduce, distribute, modify, create derivative works of, publicly display,
          publicly perform, republish, download, store, or transmit any of the Material on our Sites, except as follows:
        </p>
        <ul>
          <li>
            Your computer or Device may temporarily store copies of such Materials in random access memory (RAM)
            incidental to your accessing and viewing those Materials.
          </li>
          <li>
            You may store files that are automatically cached by your Web browser for display enhancement purposes.
          </li>
          <li>
            You may print one copy of a reasonable number of pages of the Sites for your own personal, non-commercial
            use and not for further reproduction, publication, or distribution.
          </li>
          <li>If we provide features through our Sites, you may take such actions as are enabled by such features.</li>
        </ul>
        <p>
          You must not modify copies of any Materials from the Sites or delete or alter any copyright, trademark, or
          other proprietary rights notices from copies of Materials from the Sites. If you print, copy, modify,
          download, or otherwise use or provide any other person with access to any part of the Sites in breach of the
          Terms of Use, your right to use the Sites will stop immediately and you must, at our option, return or destroy
          any copies of the Materials you have made.
        </p>
        <h3>Prohibited Uses</h3>
        <p>
          You are solely responsible for any and all acts and omissions that occur during or relating to your access
          and/or use of the Company’s Sites. You may use the Sites only for lawful purposes, only in compliance with
          applicable Laws (defined below) and in accordance with these Terms of Use. Specifically and without
          limitation, you agree not to do or attempt to do any of the following things:
        </p>
        <ul>
          <li>
            Act in any way that violates any applicable federal, state, local, or international law, statute, regulation
            or guideline (including without limitation any applicable law, statute, regulation or guideline concerning
            anti-discrimination, export controls, false advertising, unfair competition or the export of data or
            software to and from the U.S. or other countries) (collectively, “Laws”).
          </li>
          <li>
            For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them
            to inappropriate content, asking for personally identifiable information, or otherwise.
          </li>
          <li>
            Send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content
            Standards set out in these Terms of Use.
          </li>
          <li>
            Post, transmit, or procure the sending of, any advertising or promotional material, including any “junk
            mail,” “chain letter,” “spam,” or any other similar solicitation.
          </li>
          <li>
            Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or
            entity (including, without limitation, by using email addresses or online identifiers or names associated
            with any of the foregoing).
          </li>
          <li>
            Engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Sites, or which, as
            determined by us, may harm the Company or users of the Sites, or expose them to liability.
          </li>
          <li>
            Use the Sites in any manner that could disable, overburden, damage, or impair the site or interfere with any
            other party’s use of the Sites, including their ability to engage in real time activities through the Sites.
          </li>
          <li>
            Use any robot, spider, or other automatic device, process, or means to access the Sites for any purpose,
            including monitoring or copying any of the Material on the Sites.
          </li>
          <li>
            Use any manual process to monitor or copy any of the Material on the Sites, or for any other purpose not
            expressly authorized in these Terms of Use, without our prior written consent.
          </li>
          <li>
            Use any device, software, or routine that interferes with the proper working of the Sites or otherwise
            attempt to interfere with the proper working of the Sites.
          </li>
          <li>
            Introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
            technologically harmful (collectively, “Viruses”) or attack the Sites via a denial-of-service attack or a
            distributed denial-of-service attack.
          </li>
          <li>
            Modify, adapt, sub-license, translate, sell, reverse engineer, decompile, re-create, and/or disassemble any
            portion of the Sites or their source codes or attempt to do any of the foregoing.
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Sites, the
            servers on which the Sites is stored, or any server, computer, or database connected to the Sites.
          </li>
          <li>
            Except as otherwise expressly permitted by the Company, frame, mirror or deep link any portion of any of the
            Sites on any other website, social media site, intranet, the Internet or any other computer network.
          </li>
          <li>Engage in data extraction and/or data mining or any similar activities on the Sites.</li>
          <li>
            Engage in any other activity that the Company determines in its sole discretion to be in conflict with the
            spirit and/or intent of these Terms of Use, the Privacy Policy, the EULA, applicable Laws or otherwise.
          </li>
          <li>Otherwise attempt to interfere with the proper working of the Sites.</li>
        </ul>
        <h3>Monitoring and Enforcement</h3>
        <p>We have the right to:</p>
        <ul>
          <li>Remove or refuse to post any User Content for any or no reason in our sole discretion.</li>
          <li>
            Take any action with respect to any User Content that we deem necessary or appropriate in our sole
            discretion, including if we believe that such User Content violates the Terms of Use, infringes any
            intellectual property right or other right of any person or entity, threatens the personal safety of users
            of the Sites or the public, or could create liability for the Company.
          </li>
          <li>
            Disclose your identity or other information about you to any third party who claims that User Content or
            other material posted by you violates their rights, including without limitation their intellectual property
            rights or their right to privacy.
          </li>
          <li>
            Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or
            unauthorized use of the Sites.
          </li>
          <li>
            Terminate or suspend your access to all or part of the Sites for any or no reason, including without
            limitation, any violation of these Terms of Use.
          </li>
        </ul>
        <p>
          Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities or
          court order requesting or directing us to disclose the identity or other information of anyone posting any
          materials on or through the Sites. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS SUBSIDIARIES, AFFILIATES,
          LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
          PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.
        </p>
        <p>
          We do not routinely monitor the Sites, Material, User Content or other information that is posted by any
          person or entity (other than the Company) to any and all sections of the Sites. However, we reserve the right
          to monitor the Sites, Material and User Content and to edit, modify, remove, redact, consolidate and/or
          clarify any Material, User Content and/or other information that we in our sole discretion consider to violate
          these Terms of Use, be unlawful and/or for any other reason whatsoever. The Company’s exercise of such
          reserved rights shall not create any obligation whatsoever for the Company to continue any such monitoring,
          editing, modifying, removing, redacting, consolidating or clarifying.
        </p>
        <h3>Copyrights and Copyright Agent</h3>
        <p>
          The Company respects the intellectual property rights of others. Pursuant to Title 17, United States Code,
          Section 512, which is a part of the United States Digital Millennium Copyright Act (the “DMCA”), notifications
          of claimed copyright infringement on any of the Sites should be sent to the Company’s designated agent. If you
          believe that your User Content or other materials have been used in a way that may constitute copyright
          infringement, you should promptly furnish the following information by written notice to the Company’s
          designated agent:
        </p>
        <p>
          a. An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
          interest;
          <br />
          b. A description of the copyrighted work that you claim has been infringed;
          <br />
          c. A description of the location on the Sites where the material that you claim is infringing is located;
          <br />
          d. Your name, address, telephone number and email address;
          <br />
          e. A written statement by you that you have a good faith belief that the disputed use is not authorized by the
          copyright owner, its agent or the law; and
          <br />
          f. A statement by you, made under penalty of perjury, that the information in your notice is accurate, and
          that you are the copyright owner or authorized to act on the copyright owner’s behalf.
        </p>
        <p>
          The Company’s designated agent for notice of claims of copyright infringement on any of the Sites is its
          copyright agent, who may be reached as follows:
        </p>
        <p>
          Copyright Agent
          <br />
          Modaramo Media, Inc.
          <br />
          2 Seaview Blvd, Suite 100
          <br />
          Port Washington, NY 11050
          <br />
          516-864-8485
          <br />
          <a href="mailto:info@reliefscout.com" rel="noreferrer">
            info@reliefscout.com
          </a>
        </p>
        <p>
          If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA notification
          may not be effective. The Company has adopted and reasonably implemented a policy that provides for the
          termination in appropriate circumstances of users of the Sites who are repeat infringers of copyright.
        </p>
        <h3>Termination</h3>
        <p>
          Your limited right to access and use the Sites is licensed to you by the Company and is subject to all of the
          terms and condition of these Terms of Use, the Privacy Policy, the EULA and applicable Laws. At the Company’s
          sole discretion, this license shall immediately and automatically terminate without notice to you upon your
          breach and/or threatened or anticipated breach of these Terms of Use, the Privacy Policy, the EULA or
          applicable Laws. In addition, the Company may terminate said license immediately at any time, at shall, for
          any reason or no reason, and/or for the convenience of the Company. IF YOU DO NOT ABIDE BY THESE TERMS OF USE,
          THE PRIVACY POLICY, THE EULA, AND APPLICABLE LAWS, YOU ARE NOT AUTHORIZED TO ACCESS OR USE THE COMPANY’S
          SITES.
        </p>
        <h3>Linking to the Sites and Features</h3>
        <p>
          You may link to our Website homepage, provided you do so in a way that is fair and legal and does not damage
          our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form
          of association, approval, or endorsement on our part without our express written consent.
        </p>
        <p>
          The Sites may provide certain features that enable you to cause limited portions of Material on the Sites to
          be displayed or appear to be displayed on your own or certain third-party websites. You may use these features
          solely as they are provided by us, solely with respect to the content they are displayed with, and otherwise
          in accordance with any additional terms and conditions we provide with respect to such features. Subject to
          the foregoing, you must not: (i) establish a link from any website or social media account or webpage that is
          not owned by you; (ii) cause the Sites or portions of it to be displayed on, or appear to be displayed by, any
          other website, social media account or webpage, for example, framing, deep linking, or in-line linking; or
          (iii) otherwise take any action with respect to the Materials on the Sites that is inconsistent with any other
          provision of these Terms of Use. You agree to cooperate with us in causing any unauthorized framing or linking
          immediately to stop. We may disable all or any features on the Sites and any links at any time without notice
          in our discretion.
        </p>
        <h3>Links from the Sites</h3>
        <p>
          The Company’s Sites may provide hyperlinks to and/or other methods of viewing or accessing other web pages,
          websites, links, social media accounts, sites and platforms, applications and other resources that are
          available on the Internet, including without limitation the Programs (hereinafter defined) (collectively,{" "}
          <strong>“Third Party Content”</strong>). This includes links contained in advertisements, including banner
          advertisements and sponsored links. The Company has no control over any Third Party Content that you access or
          use via such hyperlinks or otherwise. The Company expressly disclaims any and all responsibility for any Third
          Party Content or for the consequences of your access or use of any Third Party Content, or for any content,
          advertising, products, services, information or other materials on or available from such Third Party Content.
          You acknowledge and agree that the Company shall not be responsible or liable for any damage or loss of any
          kind whatsoever caused or alleged to have been caused in connection with your use of or reliance on any Third
          Party Content or any advertising, products, services, information or other materials that may be available on
          or through any such Third Party Content. It is your sole responsibility to take any and all precautions that
          you deem necessary and/or appropriate to ensure that any Third Party Content that you may choose to access and
          use is safe, appropriate for you and/or any other person (including children) viewing such Third Party Content
          who is accompanying you and/or does not contain any inaccuracies, omissions, Viruses and/or other items of a
          destructive nature.
        </p>
        <h3>ReliefScout Programs</h3>
        <p>
          The Company allows registered users of the App to provide certain information on the App and receive
          information regarding programs, resources and opportunities that registered users may qualify for (
          <strong>“Programs”</strong>). By receiving any information regarding Programs, you represent that you are at
          least eighteen (18) years of age or otherwise of legal age to enter into these Terms of Use. Your receipt of
          any information regarding Programs is governed by these Terms of Use and any other applicable terms,
          conditions and policies, including those from third parties that administer the Programs. You agree that third
          parties that administer the Programs may charge fees in connection with any Programs, including but not
          limited to any service charges, and you shall be solely responsible for the payment of such fees. The Company
          in no way represents that it reviews, inspects, verifies or otherwise examines any services or products
          offered in the Programs, including but not limited to the quality or availability of such services or
          products, and Company expressly disclaims all such responsibility for such review, inspection, verification or
          examination. Your use of any services or products from the Programs is at your own risk. Company shall not
          assume any liability for any services or products offered through the Programs. Company shall not be
          responsible for any inaccurate, wrong or mistaken information in connection with any services or products
          offered through the Programs, including but not limited to any typographical errors, inaccuracies or omissions
          related to pricing and availability.
        </p>
        <p>
          You should thoroughly review any services or products offered through the Sites, including any Programs, and
          make your own independent determination as to whether you want to purchase such services or products. Company
          does not guarantee that any such service or product descriptions are accurate, complete or available. You are
          solely responsible to verify the completeness, accuracy and availability of any service or production
          descriptions on the Sites, including any Programs in the App.
        </p>
        <p>
          You are solely responsible for all consequences and damages arising from all uses of your account for the
          Sites, whether or not authorized by you. You agree to notify the Company immediately of any unauthorized use
          of your account for the Sites.
        </p>
        <h3>Reliance on Information Posted</h3>
        <p>
          The Material on the Sites, including without limitation regarding any products or services, as well as any
          Programs, are made available solely for informational purposes. Such information is not intended as financial
          advice, credit advice, legal advice or any other form of professional advice and you may not rely on such
          information for such purpose. You should consult with an appropriate professional for advice as it relates to
          the facts and circumstances of your specific situation. We do not warrant the accuracy, completeness, or
          usefulness of any such Material or Programs, or that any such Material or Programs indicates an endorsement or
          business relationship between the Company and any other individual or entity. Any reliance you place on such
          Material or Programs is strictly at your own risk. We disclaim all liability and responsibility arising from
          any reliance placed on such Material or Programs by you or any other visitor to the Sites, or by anyone who
          may be informed of any of its contents.
        </p>
        <p>
          The Sites may include Material provided by third parties, including without limitation Material provided by
          other users and third party licensors. All statements and/or opinions expressed or provided in these
          Materials, other than Material directly provided by the Company, as well as the Programs, are solely the
          opinions and the responsibility of the person or entity providing those Materials or Programs. These Materials
          and Programs do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you
          or any third party, for the content or accuracy of any Programs or of any Materials on the Sites provided by
          or through any third parties.
        </p>
        <h3>Disclaimer of Warranties</h3>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY: (I) HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
          EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
          NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE; AND (II) WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
          CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
          INFECT YOUR COMPUTER, DEVICE, EQUIPMENT, PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL. YOUR USE OF THE SITES,
          THEIR MATERIAL, AND ANY PRODUCTS, SERVICES OR ITEMS OBTAINED THROUGH THE SITES, AND OF ANY PROGRAMS, IS AT
          YOUR OWN RISK. THE SITES, THEIR MATERIAL, AND ANY PRODUCTS, SERVICES OR ITEMS OBTAINED THROUGH THE SITES, AND
          ANY PROGRAMS, ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
        </p>
        <p>
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY REPRESENTS OR
          WARRANTS THAT THE SITES, THEIR MATERIAL, OR ANY PRODUCTS, SERVICES OR ITEMS OBTAINED THROUGH THE SITES, OR ANY
          PROGRAMS, WILL BE ACCURATE, COMPLETE, TIMELY, RELIABLE, SECURE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
          WILL BE CORRECTED, THAT OUR SITES OR THE SERVERS THAT MAKE THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS, OR THAT THE SITES OR ANY PRODUCTS, SERVICES OR ITEMS OBTAINED THROUGH THE SITES, AND ANY PROGRAMS,
          WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
          EXCLUDED OR LIMITED UNDER APPLICABLE LAWS. YOU ACKNOLWEDGE THAT THE SITES MAY INCLUDE TECHNICAL INACCURACIES
          OR TYPOGRAPHICAL ERRORS. COMPANY ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN ANY
          MATERIAL ON THE SITES.
        </p>
        <h3>Limitation on Liability</h3>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS SUBSIDIARIES, AFFILIATES, AND ITS AND
          THEIR RESPECTIVE EMPLOYEES, AGENTS, OFFICERS, DIRECTORS, MEMBERS, CONTRACTORS, LICENSORS, SERVICE PROVIDERS,
          SUPPLIERS, SUCCESSORS, AND ASSIGNS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF
          OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SITES (INCLUDING BUT NOT LIMITED TO THE APP), ANY
          WEBSITES LINKED TO THEM, ANY MATERIAL ON THE SITES, ANY PROGRAMS, OR ANY CONTENT ON SUCH OTHER LINKED
          WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING
          BUT NOT LIMITED TO, PERSONAL INJURY, DEATH, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
          PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER
          CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>
        <p>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        <h3>Indemnification</h3>
        <p>
          You agree to defend, indemnify, and hold harmless the Company, its subsidiaries, affiliates, and its and their
          respective officers, directors, members, employees, contractors, agents, licensors, service providers,
          suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
          losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to: (a)
          your actual or alleged violation of these Terms of Use, the Privacy Policy or the EULA; (b) your use of the
          Sites; (c) your use of any Programs; (d) your User Content, including without limitation any claims of
          intellectual property infringement; (e) any use of the Sites’ Material, products and services other than as
          expressly authorized in these Terms of Use, or (f) your use of any Material obtained from the Sites. The
          Company reserves the right to assume, at your sole expense, the exclusive defense and control of any matter
          that is subject to indemnification by you hereunder. In such event, you agree to cooperate fully at your sole
          expense with the Company in asserting, prosecuting or alleging any and all available defenses, counterclaims,
          cross-claims and any and all other pleadings, allegations or evidence and in all other respects at the
          Company’s written request at any time.
        </p>
        <h3>Governing Law and Binding Arbitration</h3>
        <p>
          All matters relating to the Sites, these Terms of Use, the Privacy Policy and the EULA shall be interpreted
          under and governed by the substantive laws of the United States of America and the State of New York, without
          regard to principles of conflict of laws. You and the Company have agreed to these Terms of Use, the Privacy
          Policy and the EULA within the State of New York for all purposes.
        </p>
        <p>
          Subject to all applicable laws, you are agreeing to give up: (i) your right to litigate any claims that may
          arise hereunder in court or before a jury; and (ii) your right to consolidate any claim and/or participate in
          any class action claim that may arise hereunder in any manner or forum. Instead, any claim, dispute, or
          controversy of any kind or nature arising hereunder which cannot be amicably resolved by us shall be solely
          and finally settled by arbitration administered by the American Arbitration Association in accordance with its
          commercial arbitration rules. Judgment on the award rendered by the arbitrator(s) may be entered in any court
          having jurisdiction thereof. The arbitration shall take place before a panel of one arbitrator sitting in
          Nassau County, New York. The language of the arbitration shall be English. The arbitrator will be bound to
          adjudicate all disputes in accordance with the laws of the State of New York. The decision of the
          arbitrator(s) shall be in writing with written findings of fact and shall be final and binding on the parties.
          Each party shall bear all of its own costs, including but not limited to attorneys’ fees, actually incurred in
          connection with any such arbitration proceedings; provided, however, that if Company is the prevailing party,
          then it shall be entitled to reimbursement for its reasonable attorneys’ fees and related costs expended in
          connection with the arbitration. With respect to any arbitration hereunder, as stated above, you hereby
          expressly waive any right to consolidate any claim and/or participate in any class-action claim of any kind or
          nature. This Governing Law and Binding Arbitration section provides your sole recourse for the settlement of
          any disputes arising out of, in connection with, or related to these Terms of Use and, unless expressly stated
          otherwise, the Privacy Policy and the EULA.
        </p>
        <h3>General Terms</h3>
        <p>
          These Terms of Use, the Privacy Policy, the EULA and any other terms or conditions posted on any of the
          Company’s Sites, constitute the entire agreement between you and the Company with respect to the matters
          herein and therein and supersede all prior and contemporaneous understandings, agreements, representations,
          and warranties, written and oral, between the Company and you. No action or inaction by the Company shall be
          construed as a waiver of these Terms of Use, the Privacy Policy, the EULA or any other terms or conditions
          posted on any of the Company’s Sites. No waiver by the Company of any term or condition in these Terms of Use,
          the Privacy Policy, the EULA or any other terms or conditions posted on any of the Company’s Sites shall be
          deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition. If
          any of the provisions of these Terms of Use, the Privacy Policy, the EULA or any other terms or conditions
          posted on any of the Company’s Sites are held to be invalid, unenforceable or illegal, such provision shall be
          eliminated or limited to the minimum extent such that the validity and enforceability of the remaining
          provisions of these Terms of Use, the Privacy Policy, the EULA and any other terms or conditions posted on any
          of the Company’s Sites shall not be effected thereby and shall continue in full force and effect.
        </p>
      </div>
      <Footer />
    </div>
  );
};
export default TermsPage;
