import Header from "Core/components/Header";
import ProgramList from "Program/components/ProgramList";
import NavigationMenu from "Core/components/NavigationMenu";
import ProgramListSelect from "Program/components/ProgramListSelect";

const ProgramListPage: React.FC = () => {
  return (
    <div>
      <Header center={<NavigationMenu active="program" />} />
      <ProgramListSelect />
      <ProgramList />
    </div>
  );
};

export default ProgramListPage;
