import cx from "Core/utils/cx";
import { useScroll } from "Core/hooks";
// import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import LogoUrl from "Core/assets/logo.svg";
import Button from "Core/components/Button";
import { Fragment, ReactNode, useState } from "react";
// import AccountButton from "Auth/components/AccountButton";

type Props = {
  light?: boolean;
  right?: ReactNode;
  center?: ReactNode;
  left?: ReactNode | (() => void);
};

const Header: React.FC<Props> = ({ left, center, right, light = false }) => {
  const [scrolled, setScrolled] = useState(false);

  useScroll((top) => {
    setScrolled(top > 100);
  });

  return (
    <header className={cx(styles.container, [styles.light, light], [styles.scrolled, scrolled])}>
      <div className={styles.content}>
        <div className={styles.left}>
          {left ? (
            typeof left === "function" ? (
              <Button type="light" icon="chevronLeft" onClick={() => left()} />
            ) : (
              left
            )
          ) : (
            <img src={LogoUrl} alt="ReliefScout" />
          )}
        </div>
        <div className={styles.center}>{center}</div>
        <div className={styles.right}>
          {right ? (
            right
          ) : (
            <Fragment>
              {/* <Link to="/profile">
                <AccountButton />
              </Link> */}
            </Fragment>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
