import { Fragment } from "react";
import { NavigationMenu, Header } from "Core/components";
import ResourceBookmarks from "Resource/components/ResourceBookmarks";

const ResourceBookmarksPage: React.FC = () => {
  return (
    <Fragment>
      <Header center={<NavigationMenu active="resource" />} />
      <ResourceBookmarks />
    </Fragment>
  );
};

export default ResourceBookmarksPage;
