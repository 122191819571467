import useAuth from "Auth/context";
import { useMounted } from "Core/hooks";
import { LoadingOverlay } from "Core/components";
import { Outlet, useNavigate } from "react-router-dom";

const RootLayout: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  useMounted(() => {
    if (!auth.data) {
      auth.getData().catch(() => {
        navigate("/");
      });
    }
  });

  return <LoadingOverlay loading={auth.loading}>{auth.data ? <Outlet /> : <></>}</LoadingOverlay>;
};

export default RootLayout;
