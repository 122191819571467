import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import useTranslate from "Core/context/translate";
import TermsDrawer from "Profile/components/TermsDrawer";
import ProfileCard from "Profile/components/ProfileCard";

const NavigationMenu: React.FC = () => {
  const { t } = useTranslate();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Link to="/profile/edit">
          <ProfileCard title={t("profile.menu.editProfile.title")} info={t("profile.menu.editProfile.description")} />
        </Link>
        <TermsDrawer />
      </div>
    </div>
  );
};

export default NavigationMenu;
