import useAuth from "Auth/context";
import styles from "./styles.module.scss";
import { Button, Icon } from "Core/components";
import useTranslate from "Core/context/translate";

const LogoutButton: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslate();

  return (
    <Button className={styles.container} type="secondary" onClick={auth.logout}>
      {t("profile.menu.logout.title")} <Icon name="arrowRight" />
    </Button>
  );
};

export default LogoutButton;
