import styles from "./styles.module.scss";
import { Icon, Progress } from "Core/components";

type Props = {
  info: string;
  title: string;
  percent?: number;
};

const ProfileCard: React.FC<Props> = ({ percent, title, info }) => {
  return (
    <section className={styles.container}>
      {percent !== undefined && <Progress className={styles.percent} percent={percent} />}
      <div className={styles.content}>
        <h4>{title}</h4>
        <h5>{info}</h5>
      </div>
      <Icon name="chevronRight" className={styles.icon} />
    </section>
  );
};

export default ProfileCard;
