import { Fragment } from "react";
import { NavigationMenu, Header } from "Core/components";
import ResourceResult from "Resource/components/ResourceResult";
import ResourceBookmarksButton from "Resource/components/ResourceBookmarksButton";

const ResourceResultPage: React.FC = () => {
  return (
    <Fragment>
      <Header center={<NavigationMenu active="resource" />} right={<ResourceBookmarksButton />} />
      <ResourceResult />
    </Fragment>
  );
};

export default ResourceResultPage;
