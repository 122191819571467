import { Header } from "Core/components";
import { useNavigate } from "react-router-dom";
import PasswordForm from "Profile/components/PasswordForm";
import useTranslate from "Core/context/translate";

const ProfilePasswordPage: React.FC = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const onLeft = () => {
    navigate(-1);
  };

  return (
    <div>
      <Header left={onLeft} center={t("profile.edit.changePassword.title")} right={<></>} />
      <PasswordForm />
    </div>
  );
};

export default ProfilePasswordPage;
