import { getAvatar } from "Auth/utils";
import styles from "./styles.module.scss";
import useAuth from "Auth/context";
import IntroUrl from "Profile/assets/intro.svg";
import getUserLabel from "Auth/utils/getUserLabel";

const ProfileIntro: React.FC = () => {
  const auth = useAuth();
  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <img src={IntroUrl} alt={getUserLabel(auth.data!)} />
      </div>
      <div className={styles.content}>
        <img src={getAvatar(auth.data?.avatar)} alt={getUserLabel(auth.data!)} className={styles.avatar} />
        <h4>{getUserLabel(auth.data!)}</h4>
        <h5>{auth.data?.email}</h5>
      </div>
    </div>
  );
};

export default ProfileIntro;
